import React from "react";
import ProgressBar from "./ProgressBar";

const LineBarData = [
  { type: "5 stars", percentage: 80 },
  { type: "4 stars", percentage: 60 },
  { type: "3 stars", percentage: 40 },
  { type: "2 stars", percentage: 20 },
  { type: "1 stars", percentage: 10 },
];

function renderBars() {
  return LineBarData.map((language, idx) => {
    return (
      <div className="bar-container" key={idx}>
        <p className="skill-title">{language.type}</p>
        <ProgressBar percentage={language.percentage} />
      </div>
    );
  });
}

const ProgressLineBar = () => {
  return <div className="progress_container">{renderBars()}</div>;
};

export default ProgressLineBar;
