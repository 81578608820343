import React from "react";

const CourseOverview = ({ courseDetail }) => {
  const cleanedLearningOutcomes = courseDetail.learningOutcomes
    ? courseDetail.learningOutcomes.trim().replace(/\.$/, "")
    : "";

  const learningOutcomes = cleanedLearningOutcomes
    ? cleanedLearningOutcomes
        .split(".")
        .filter((outcome) => outcome.trim() !== "")
    : [];

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="course-content">
          <div className="course-content-1">
            <h4 className="course-content-heading">Course Description</h4>
            <p>{courseDetail.courseDescription}</p>
          </div>
          <div className="course-content-1">
            <h4 className="course-content-heading">Certification</h4>
            <p>{courseDetail.certification}</p>
          </div>
          <div className="course-content-1">
            <h4 className="course-content-heading">Learning Outcomes</h4>
            <div className="row">
              <div className="col-lg-12">
                <ul
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "0px 25px",
                  }}
                >
                  {learningOutcomes.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseOverview;
