import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import Certificate from "../../components/certificate/Certificate";

const Verify = () => {
  const navigate = useNavigate();
  const { certificateId: paramCertificateId } = useParams();
  const [certificateId, setCertificateId] = useState(paramCertificateId || "");
  const [showCertificate, setShowCertificate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (paramCertificateId && !certificateId) {
      handleVerify(paramCertificateId);
    }
  }, [paramCertificateId, certificateId]);

  const handleVerify = (inputCertificateId) => {
    const formattedCertificateId = inputCertificateId.trim().toUpperCase();

    if (formattedCertificateId === "") {
      setErrorMessage("Enter CertificateId for Verify");
    } else {
      setCertificateId(formattedCertificateId);
      setShowCertificate(true);
      setErrorMessage("");
    }
    navigate(`/verify/${formattedCertificateId}`, { replace: true });
  };

  const handleCloseCertificate = () => {
    setShowCertificate(false);
    navigate("/verify");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleVerify(document.getElementById("certificateId").value);
    }
  };

  return (
    <div>
      <div className="sub-banner">
        <h1 className="h1-large">
        Online Certificate Verification <br/> Authenticate and Validate Certificates for Assurance
        </h1>
      </div>

      <div className="main container sub-banner verifypage">
        {showCertificate ? (
          <div className="certificate-popup">
            <Certificate certificateId={certificateId} />
            <button className="close-bt" onClick={handleCloseCertificate}>
              x
            </button>
          </div>
        ) : (
          <div className="input-group mb-3">
            <input
              style={{ height: "100%", borderRadius: 4 }}
              id="certificateId"
              type="text"
              className="form-control"
              placeholder="Enter Certificate Id"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              onKeyPress={handleKeyPress}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary verify-button"
                type="button"
                style={{ width: "100px", height: "100%", marginLeft: 10, borderRadius: 4 }}
                onClick={() => handleVerify(document.getElementById("certificateId").value)}
              >
                Verify
              </button>
            </div>
          </div>
        )}

        {errorMessage && (
          <div className="error-message " style={{ color: "red", textAlign: "left", width: "74%" }}>
            {errorMessage}
          </div>
        )}

        {showCertificate && errorMessage && (
          <div className="error-message " style={{ color: "red", textAlign: "left", width: "74%" }}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default Verify;
