import React, { useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FaRegCalendar, FaRegClock } from "react-icons/fa";
import WorkshopTeam from "../../components/workshopTeam";
import workshopBanner from "../../images/workshop-detail-banner.png";
import ReactGA from "react-ga4";

export default function WorkshopDetails() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <div className="workshop-detail-view">
      <div className="workshop-detail-sub-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="workshop-detail-heading">
                <Breadcrumb>
                  <Breadcrumb.Item href="/#/workshop">Workshop</Breadcrumb.Item>
                  <Breadcrumb.Item active>Workshop View</Breadcrumb.Item>
                </Breadcrumb>
                <h1 className="h1-large">
                  Creating Futures Through Technology <br /> Conference
                </h1>
              </div>
              <div className="workshop-detail-banner">
                <div className="date-time-display">
                  <div className="fw-medium me-4 mb-2">
                    <FaRegCalendar />
                    <div className="date_time mx-2">MAY 1, 2023</div>
                  </div>
                  <div className="fw-medium mb-2">
                    <FaRegClock />
                    <div className="date_time mx-2">10.30 AM</div>
                  </div>
                </div>
                <img src={workshopBanner} alt="" loading="lazy"/>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="mb-4">About Workshop</h3>
              <p className="ws-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                molestie, mauris quis dapibus lobortis, mauris enim faucibus
                quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam vel
                porta rhoncus, orci massa eleifend velit, sit amet vehicula odio
                tellus in magna. Cras et posuere diam. Aliquam suscipit dolor
                sit amet mauris rhoncus feugiat. Morbi ac diam ut purus dapibus
                ultrices. Morbi venenatis accumsan elit vitae vestibulum.
                Vivamus mattis consectetur justo sed dictum. In viverra
                consequat felis vel mattis. Cras ut facilisis nulla, sit amet
                condimentum sem.
              </p>
              <p className="ws-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                molestie, mauris quis dapibus lobortis, mauris enim faucibus
                quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam vel
                porta rhoncus, orci massa eleifend velit, sit amet vehicula odio
                tellus in magna. Cras et posuere diam. Aliquam suscipit dolor
                sit amet mauris rhoncus feugiat. Morbi ac diam ut purus dapibus
                ultrices. Morbi venenatis accumsan elit vitae vestibulum.
                Vivamus mattis consectetur justo sed dictum. In viverra
                consequat felis vel mattis. Cras ut facilisis nulla, sit amet
                condimentum sem.
              </p>
              <p className="ws-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                molestie, mauris quis dapibus lobortis, mauris enim faucibus
                quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam vel
                porta rhoncus, orci massa eleifend velit, sit amet vehicula odio
                tellus in magna. Cras et posuere diam. Aliquam suscipit dolor
                sit amet mauris rhoncus feugiat. Morbi ac diam ut purus dapibus
                ultrices. Morbi venenatis accumsan elit vitae vestibulum.
                Vivamus mattis consectetur justo sed dictum. In viverra
                consequat felis vel mattis. Cras ut facilisis nulla, sit amet
                condimentum sem.
              </p>
            </div>
            <div className="col-lg-5">
              <div className="workshop-cost-detail">
                <h3>Cost Details</h3>
                <div className="free-cost">
                  <div className="cost">Cost</div>
                  <div className="free">Free</div>
                </div>
                <input type="text" />
                <button className="success">This event is completed</button>
              </div>
              <div className="map-location">
                <h3 className="pb-2">Location: Java Home cloud</h3>
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.3433181132737!2d77.69840941430418!3d12.949869618859779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae123562bb711d%3A0x9228e6d088618234!2sJava%20Home%20Cloud!5e0!3m2!1sen!2sin!4v1676793633836!5m2!1sen!2sin"
                    allowFullScreen={true}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="workshop-detail-team-member">
            <h3>Workshop Team</h3>
            <WorkshopTeam />
          </div>
        </div>
      </div>
    </div>
  );
}
