import React from "react";
import { Rating } from "react-simple-star-rating";

function RatingReview() {
  return (
    <div className="rating_section">
      <div className="rating_count">4.2</div>
      <Rating
        size={20}
        fillColor="#15A8F3"
        transition
        readonly={true}
        allowFraction
        initialValue={4.7}
      />
    </div>
  );
}
export default RatingReview;
