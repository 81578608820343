import React from "react";

const PlacementDescription = () => {
  const styles = {
    color: "var(--des-lgt-color)",
    padding: "0px",
    margin: "0px",
    textAlign: "justify"
  };
  return (
    <>
      <div className="trust-des">
        <p style={styles} className="des-txt">
          Our job placement program is designed to empower our students with the
          skills and confidence needed to secure rewarding career opportunities.
          We offer personalized career guidance, including resume building and
          interview coaching, to maximize your chances of success. Our extensive
          network of industry connections and partnerships enables us to match
          you with suitable job openings. We are dedicated to supporting you
          throughout your job search journey, providing ongoing assistance and
          resources until you find the perfect position. With our job placement
          program, you're not just learning, you're on the path to a promising
          career.
        </p>
      </div>
    </>
  );
};

export default PlacementDescription;
