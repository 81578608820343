// constants.js

export const BATCHES_DATA = [
  {
    date: "02-01-2024",
    course: "Python Full Stack",
    time: "9:00AM (IST)",
    sessionDuration: "1 Hrs - 1:30 Hrs",
    weekDays: "Tue (Mon - Fri)",
  },
  {
    date: "02-01-2024",
    course: "AWS",
    time: "8:00AM (IST)",
    sessionDuration: "1 Hrs - 1:30 Hrs",
    weekDays: "Tue (Mon - Fri)",
  },
  {
    date: "03-01-2024",
    course: "React JS",
    time: "10:30AM (IST)",
    sessionDuration: "1 Hrs - 1:30 Hrs",
    weekDays: "Wed (Mon - Fri)",
  },
  {
    date: "02-01-2024",
    course: "DevOps",
    time: "8:00AM (IST)",
    sessionDuration: "1 Hrs - 1:30 Hrs",
    weekDays: "Tue (Mon - Fri)",
  },
];

export const COURSE_LIST = [
  "AWS",
  "Python Full Stack",
  "DevOps",
  "And many more!",
];
