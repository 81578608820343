import React from "react";

export default function TabsComponent({ courses, toggleTab, toggleState }) {
  return (
    <div className="container tabs-nav tabs-nav-wdt ">
      {courses &&
        courses.map(({ title }, idx) => (
          <button
            key={idx + 1}
            className={`tabs ${toggleState === idx + 1 && "active-tab"}`}
            onClick={() => toggleTab(idx + 1)}
          >
            {title}
          </button>
        ))}
    </div>
  );
}
