import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
// import PlayIcon from "../../../images/play_icon.svg";
import PlacementImage1 from "../assets/placement-image-1.jpg";
import PlacementImage2 from "../assets/placement-image-2.jpg";
import PlacementImage3 from "../assets/placement-image-3.jpg";
import PlacementImage4 from "../assets/placement-image-4.jpg";
import PlacementImage5 from "../assets/placement-image-5.jpg";
import PlacementImage6 from "../assets/placement-image-6.jpg";
import JenkinsYoutubePlayer from "../../courseDetails/jenkinnYoutubeVideo";

const placementData = [
  {
    name: "Srinivas Reddy",
    image: PlacementImage1,
    company: "Infosys",
    role: "Java/J2EE",
  },
  {
    name: "Babitha",
    image: PlacementImage5,
    company: "Coderview",
    role: "AWS & Devops",
  },
  {
    name: "Ravi Teja",
    image: PlacementImage3,
    company: "Capgemini",
    role: "Java/J2EE",
  },
  {
    name: "Sudhir",
    image: PlacementImage4,
    company: "Coderview",
    role: "AWS & Devops",
  },
  {
    name: "Harish",
    image: PlacementImage2,
    company: "Westpac",
    role: "Java/J2EE",
  },
  {
    name: "Thulasi Ram",
    image: PlacementImage6,
    company: "Persistant System Ltd",
    role: "AWS & Devops",
  },
];

const RecentlyPlacedStudents = () => {
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState();
  const handleClose = () => {
    setShow(false);
    setSrc(null);
  };
  const handleShow = (src) => {
    if (src) {
      setShow(true);
      setSrc(src);
    }
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <>
      <h2>Recently Placed Students</h2>
      <div className="recent-placed-students-details">
        <div className="row">
          {placementData.map(({ name, image, company, role }, idx) => (
            <div className="col-lg-4 col-xl-4" key={idx}>
              <div className="got-placed">
                <div className="d-flex">
                  <div className="placement-student-img">
                    <img
                      className="img-fluid"
                      // style={{ width: "150px", height: "130px" }}
                      src={image}
                      alt={`placement-${idx + 1}`}
                      loading="lazy"
                    />
                    {/* <div
                          className="play"
                          onClick={() =>
                            handleShow(
                              "https://soni-data-bucket-2023-03.s3.ap-south-1.amazonaws.com/video1.mp4"
                            )}
                        >
                          <span>Play</span>
                          <span className="play-button">
                            <img
                              src={PlayIcon}
                              alt="PlayIcon"
                              className="play_icon img-fluid"
                            />
                          </span>
                        </div>  */}
                  </div>
                  <div className="ms-3">
                    <h6 className="h5">{name}</h6>
                    <h5 className="text-h5">Got Placed In</h5>
                    <h6 className="text-h6">{company}</h6>
                    <p>{role}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <JenkinsYoutubePlayer show={show} handleClose={handleClose} src={src} />
    </>
  );
};

export default RecentlyPlacedStudents;
