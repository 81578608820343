import Trainers from "../../../images/online.svg";
import liveProject from "../../../images/offline.svg";
import Certification from "../../../images/certification_icon.svg";
import Affordable from "../../../images/affordable_icon.svg";
import Flexibility from "../../../images/flexibility_icon.svg";
import Placement from "../../../images/placement_icon.svg";

const PlacementServicesList = [
    {
      image: Trainers,
      name: "Real Time Trainers",
      desc: "Our courses are led by experienced, real-world trainers who infuse industry insights into your learning journey. Join us to benefit from their wealth of experience and gain a competitive edge in your chosen field.",
    },
    {
      image: liveProject,
      name: "Live Projects",
      desc: "Our program includes industry-relevant projects, course demo datasets and files, as well as quizzes and assignments to enhance your learning experience. With us, you'll gain hands-on experience and sharpen your skills for a successful career.",
    },
    {
      image: Certification,
      name: "Certification",
      desc: "After successfully completing the JavaHomeCloud training program, you will earn a prestigious certification that holds significant value in the software industry, making it a valuable asset to your career. Join us and elevate your prospects in the world of technology.",
    },
    {
      image: Affordable,
      name: "Affordable Fees",
      desc: "At JavaHome Cloud, you'll find top-quality training at incredibly affordable prices, ensuring your professional development doesn't strain your budget.",
    },
    {
      image: Flexibility,
      name: "Flexibility",
      desc: "We prioritize flexibility in our programs, allowing you to customize your learning schedule to fit your needs.",
    },
    {
      image: Placement,
      name: "Placement Support",
      desc: "JavaHomeCloud offers robust placement support services to help you realize your dream job. Our dedicated team is committed to guiding you through every step of the job search process and preparing you thoroughly for interviews. Join us and let us empower you to achieve your career goals.",
    },
  ];

export default function Features() {
    
    return (
        <>
        <div className="feature-block">
              <h2>Features</h2>
              <div className="row">
                {PlacementServicesList &&
                  PlacementServicesList.map(({ image, name, desc }, idx) => (
                    <div
                      className="col-lg-4 service-main"
                      key={idx}
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="service-card">
                        <div className="service-card-body">
                          <img
                            className="img-fluid"
                            src={image}
                            alt="start chat"
                            loading="lazy"
                          />
                          <h3 className="class-h">{name}</h3>
                          <p className="lead mb-0">{desc}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* <div className="row feature-block-divider">
                {PlacementServicesList &&
                  PlacementServicesList.map(({ image, name, desc }, idx) => (
                    <div className="col-lg-4" key={idx}>
                      <div className="service-card">
                        <div className="service-card-body">
                          <img
                            className="img-fluid"
                            src={image}
                            alt="start chat"
                          />
                          <h3 className="class-h">{name}</h3>
                          <p className="lead mb-0">{desc}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div> */}
            </div>
        </>
    );
}
