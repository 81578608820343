import React from "react";
import { Rating } from "react-simple-star-rating";
import { FaArrowDown } from "react-icons/fa";

const CourseInfo = ({
  courseDetail,
  handleSyllabusShow,
  handleBookYourSlotShow,
}) => {
  return (
    <div className="course-ctg">
      <div className="container">
        <div className="placement-teacher-details">
          <div className="row">
            <div className="col-lg-2 col-xl-2">
              <div className="course-dt course-categories">
                <h6>Categories</h6>
                <h4>{courseDetail.category}</h4>
              </div>
            </div>
            <div className="col-lg-2 col-xl-2">
              <div className="course-dt course-students">
                <h6>Students</h6>
                <h4>143 (Registered)</h4>
              </div>
            </div>
            <div className="col-lg-2 col-xl-2">
              <div className="course-dt course-rv">
                <h6>Review</h6>
                <div className="review-main">
                  <Rating
                    size={20}
                    fillColor="#15A8F3"
                    transition
                    readonly={true}
                    allowFraction
                    initialValue={4.7}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 d-flex justify-content-end">
              <div className="d-inline m-1">
                <button className="btn" onClick={handleBookYourSlotShow}>
                  Book Your Slot
                </button>
              </div>
              <div className="d-inline m-1">
                <button className="btn" onClick={handleSyllabusShow}>
                  <FaArrowDown className="btn-icon" />
                  Download Syllabus
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
