import React, { useState } from "react";
import { Modal, Row, Col, Form, Button } from "react-bootstrap";
import SyllabusImg from "../../images/download_syllabus.svg";
import SuccessImg from "../../images/success_tick.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import AxiosClient from "../../js/axiosClient";
const axiosClient = new AxiosClient({});

export default function DownloadSyllabus({ show, handleClose }) {
  const [showForm, setShowForm] = useState(true);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isOtpResent, setIsOtpResent] = useState(false);

  const params = useParams();
  //Enquiry form
  const [state, setState] = useState({
    name: "",
    phoneNumber: "",
    opt: "",
  });
  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };
  const handlePhoneInputChange = (e) => {
    setState({
      ...state,
      ["phoneNumber"]: e,
    });
  };
  const handleSubmit = (event, resendOtp, id) => {
    let userData = {};
    if (event) {
      event.preventDefault();
      userData = {
        name: state.name,
        phoneNumber: state.phoneNumber,
      };
    } else {
      userData = resendOtp;
    }
    let url =
      process.env.REACT_APP_API_BASE_URL +
      `/v1/courses/${params.courseId}/downloadSyllabusRequestOtp`;
    console.log(url);
    let data = userData;

    axiosClient
      .request({
        url: url,
        method: "POST",
        data: data,
        headers: {
          "X-Channel": "app",
          "Content-Type": "application/json",
        },
      })
      .then((resp) => {
        console.log(resp);
        setError("");
        resp = resp.data;
        if (resp?.message === "OTP sent") {
          console.log(resp);
          setShowForm(false);
          setShowVerifyOTP(true);
          toast.success("Otp Sent Successfully");
        } else if (resp?.errorMessage === "'contact'") {
          toast.error("Please Enter Name and PhoneNumber");
        } else {
          setError("Number not registered on WhatsApp");
        }
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  const handleOtpSubmit = (event, id) => {
    event.preventDefault();
    const otpVerify = {
      phoneNumber: state.phoneNumber,
      otp: state.otp,
    };
    let url =
      process.env.REACT_APP_API_BASE_URL +
      `/v1/courses/${params.courseId}/verifySyllabusDownloadOtp`;
    let data = otpVerify;
    axiosClient
      .request({
        url: url,
        method: "POST",
        data: data,
        headers: {
          "X-Channel": "app",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const resp = response.data;
        if (resp?.message === "OTP verified and syllabus sent") {
          console.log(resp);
          setShowVerifyOTP(false);
          setSuccess(true);
          setError(false);
          setShowForm(false);
          toast.success("Syllabus Sent Successfully");
        } else if (
          resp?.message === "OTP verified but syllabus sending error"
        ) {
          toast.error(resp?.message);
        } else if (resp?.message === "OTP verified but syllabus not found") {
          toast.error(resp?.message);
        } else if (resp?.message === "OTP invalid") {
          toast.error(resp?.message);
        } else if (resp?.message === "OTP expired") {
          toast.error(resp?.message);
        } else if (resp?.message === "OTP not found") {
          toast.error(resp?.message);
        } else if (resp.errorMessage === "'otp'") {
          toast.error("Please Enter OTP");
        } else {
          console.log("error", resp?.message);
          toast.error(resp?.message);
        }
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  const handleResend = () => {
    const userData = {
      name: state.name,
      phoneNumber: state.phoneNumber,
    };
    handleSubmit("", userData);
    setIsOtpResent(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      className="syllabus_modal"
    >
      <Modal.Body className="show-grid">
        <Row className="m-0">
          <Col sm={12} md={6} className="p-0">
            <div className="syllabus_content">
              <img
                src={SyllabusImg}
                alt="SyllabusImg"
                className="img-fluid"
                loading="lazy"
              />
              <p>
                Download the syllabus to learn more
                <br /> about the program
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} className="p-0">
            <closeButton onClick={handleClose} className="close-modal">
              <span aria-hidden="true">&times;</span>
            </closeButton>
            <div className="syllabus_login">
              {!success && (
                <div className="heading">
                  <h5>Send your details</h5>
                  <h2>Get Syllabus PDF on What's App </h2>
                </div>
              )}
              <div className="login">
                {showForm && (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Your Name"
                        name="name"
                        value={state.name}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    <PhoneInput
                      type="text"
                      country={"in"}
                      enableSearch={true}
                      countryCodeEditable={false}
                      onChange={(e) => handlePhoneInputChange(e)}
                      separateDialCode={true}
                      className="mb-3"
                      inputProps={{
                        name: "phoneNumber",
                      }}
                    />
                    <Button type="submit" className="w-100">
                      Submit
                    </Button>
                  </Form>
                )}
                {showVerifyOTP && (
                  <Form onSubmit={handleOtpSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Confirm the OTP"
                        name="otp"
                        value={state.otp}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    {!isOtpResent ? (
                      <Button
                        className="otp btn-link"
                        disabled={isOtpResent}
                        onClick={handleResend}
                      >
                        Resend OTP
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button type="submit" className="w-100">
                      Get Verify
                    </Button>
                  </Form>
                )}
                {/* {error && <p>{error}</p>} */}
                {success && (
                  <div className="success_message">
                    <img
                      src={SuccessImg}
                      alt=""
                      className="img-fluid"
                      loading="lazy"
                    />
                    <h5>
                      Successfully Verified
                      <br /> We have sent file to your
                      <br /> What's app
                    </h5>
                    <Button
                      type="submit"
                      className="w-100"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <ToastContainer position="top-right" />
      </Modal.Body>
    </Modal>
  );
}
