import React, { useState, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InternshipForm = () => {
  const initialFormData = {
    name: "",
    college_name: "",
    mobile_number: "",
    message: "",
    resume: null,
    role: null,
    graduation_year: null,
    internship: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const roleOptions = [
    { value: "student", label: "Student" },
    { value: "fresher", label: "Fresher" },
    { value: "workingProfessional", label: "Working Professional" },
  ];

  const skillsOptions = [
    { value: "Python", label: "Python" },
    { value: "Gen AI", label: "Generative AI" },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "5px",
      backgroundColor: "#f3f4f8",
      fontSize: "15px",
      border: "none",
      paddingInline: "10px",
      paddingBlock: "5px",
    }),
  };

  const graduationYearOptions = Array.from({ length: 10 }, (_, i) => ({
    value: 2020 + i,
    label: 2020 + i,
  }));

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error(
          "File size exceeds 5MB limit. Please choose a smaller file."
        );
        e.target.value = null;
      } else {
        setFormData({ ...formData, resume: file });
      }
    }
  };

  const handleRoleChange = (selectedOption) => {
    setFormData({ ...formData, role: selectedOption });
  };

  const handleGraduationYearChange = (selectedOption) => {
    setFormData({ ...formData, graduation_year: selectedOption });
  };

  const handleSkillsChange = (selectedOption) => {
    setFormData({ ...formData, internship: selectedOption });
  };

  const resetForm = () => {
    setFormData(initialFormData);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.mobile_number.length !== 10) {
      toast.error("Mobile number must be exactly 10 digits.");
      return;
    }

    setIsLoading(true);

    const formSubmission = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "role" || key === "graduation_year" || key === "internship") {
        formSubmission.append(key, value ? value.value : "");
      } else {
        formSubmission.append(key, value);
      }
    });

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v1/apply",
        formSubmission,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Channel": "app",
          },
        }
      );
      console.log("Form submitted successfully:");
      // console.log("Form submitted successfully:", response.data);
      toast.success("Application submitted successfully!");
      resetForm();
    } catch (error) {
      toast.error("Error submitting application. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="internship-form-container">
      <form onSubmit={handleSubmit} className="internship-form">
        <div className="form-grid">
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Full Name"
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              inputMode="numeric"
              pattern="\d{10}"
              maxLength={10}
              id="mobile_number"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={handleInputChange}
              required
              placeholder="Mobile Number"
            />
          </div>
          <div className="form-group">
            <div className="select-wrapper">
              <Select
                styles={customStyles}
                id="role"
                name="role"
                options={roleOptions}
                onChange={handleRoleChange}
                value={formData.role}
                placeholder="Role"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#a6a4a4",
                  },
                })}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="select-wrapper">
              <Select
                styles={customStyles}
                id="graduation_year"
                name="graduation_year"
                options={graduationYearOptions}
                onChange={handleGraduationYearChange}
                value={formData.graduation_year}
                placeholder="Year of Graduation"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#a6a4a4",
                  },
                })}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="select-wrapper">
              <Select
                styles={customStyles}
                id="skills"
                name="skills"
                options={skillsOptions}
                onChange={handleSkillsChange}
                value={formData.internship}
                placeholder="Select Internship"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#a6a4a4",
                  },
                })}
              />
            </div>
          </div>
          <div className="form-group">
            <input
              type="text"
              id="college_name"
              name="college_name"
              value={formData.college_name}
              onChange={handleInputChange}
              required
              placeholder="College Name"
            />
          </div>
        </div>

        <div className="form-group">
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            rows="4"
            placeholder="Message..."
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="resume">
            Upload Resume <span> (Max 5 MB)</span>
          </label>
          <div className="file-input-wrapper">
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".pdf"
              onChange={handleResumeChange}
              required
              ref={fileInputRef}
            />
            <span className="btn btn-sky-blue">Choose file</span>
            {formData.resume && (
              <div className="selected-file">{formData.resume.name}</div>
            )}
          </div>
        </div>
        <hr />
        <button
          type="submit"
          className="btn btn-sky-blue internship-submit-button"
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit Application"}
        </button>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
      />
    </div>
  );
};

export default InternshipForm;
