import React from "react";

const studentsCount = [
  { count: 10500, counterName: "REGISTERED STUDENTS" },
  { count: 2500, counterName: "FINISHED COURSES" },
  { count: 1500, counterName: "SUCCESSFUL PLACEMENT" },
  { count: "98%", counterName: "SATISFACTION RATE" },
];
export default function CounterCell() {
  const convertToLocalFormat = (number) => {
    return typeof number === "number" ? number.toLocaleString()+"+" : number;
  };
  return (
    <div className="counter">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="counter-container">
              {studentsCount &&
                studentsCount.map(({ count, counterName }, idx) => (
                  <div className="counter-cell" key={idx}>
                    <div className="purecounter">
                      {convertToLocalFormat(count)}
                    </div>
                    <div className="counter-info">{counterName}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
