import React, { useState, useEffect } from "react";
//import { Rating } from "react-simple-star-rating";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import Rate from "../ratings/rating";
import AxiosClient from "../../js/axiosClient";

const axiosClient = new AxiosClient({});
function Comments() {
  const [data, setData] = useState("");
  const [error, setError] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const getCourseDetails = async () => {
    try {
      const response = await axiosClient.request({
        url: process.env.REACT_APP_API_BASE_URL + "/v1/courses",
        method: "GET",
        headers: {
          "X-Channel": "app",
        },
      });
      if (response.data) {
        let courseCategorySet = new Set();
        response.data.courses.map((course, idx) => {
          courseCategorySet.add(course.category);
        });
        let dataTabs = [];
        let allCoursesList = { title: "All", courses: response.data.courses };
        dataTabs.push(allCoursesList);
        Array.from(courseCategorySet).map((category) => {
          let filteredCoursesList = {
            title: category,
            courses: response.data.courses.filter(
              (x) => x.category === category
            ),
          };
          dataTabs.push(filteredCoursesList);
        });
        setData(dataTabs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCourseDetails();
  }, []);
  //Enquiry form
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const message = event.target.message.value;
    if (name.trim() === "") {
      setError(true);
      return;
    }
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError(true);
      return;
    }
    if (message.trim() === "") {
      setError(true);
      return;
    }

    const userData = {
      name: state.name,
      email: state.email,
      message: state.message,
    };
    //axios;
    let url = process.env.REACT_APP_API_BASE_URL + "enquiryNow";
    let data = userData;
    axiosClient
      .request({
        url: url,
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
        data: data,
      })
      .then((res) => {
        setIsOpened((wasOpened) => !wasOpened);
        setFormSubmitted(true);
        setError(false);
        setTimeout(() => {
          setState({
            name: "",
            email: "",
            message: "",
          });
          setIsOpened(false);
          setError(false);
          setFormSubmitted(false);
        }, [6000]);
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  useEffect(() => {
    setFormSubmitted(false);
  }, [formSubmitted]);
  return (
    <div className="comment_section">
      <div className="rate_us">
        <div className="rate_heading">Rate Us</div>
        <Rate />
      </div>
      <div className="comment_box">
        <span>Leave your comment</span>
        <div className="form-container">
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} className="form-divider">
              <Col sm="3" className="input_sec">
                <Form.Control
                  type="name"
                  placeholder="Your Name"
                  name="name"
                  value={state.name}
                  onChange={(e) => handleChange(e)}
                  style={{ marginBottom: "15px" }}
                />
                {error && state.name === "" ? (
                  <label className="errorMsg">Name can't be Empty</label>
                ) : (
                  ""
                )}
                <Form.Control
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  value={state.email}
                  onChange={(e) => handleChange(e)}
                  style={{ marginBottom: "15px" }}
                />
                {error && state.email === "" ? (
                  <label className="errorMsg">Email can't be Empty</label>
                ) : (
                  ""
                )}
              </Col>
              <Col sm="9" className="message_sec">
                <Form.Control
                  className="form-message"
                  type="message"
                  placeholder="Message"
                  name="message"
                  value={state.message}
                  onChange={(e) => handleChange(e)}
                  as="textarea"
                  rows={3}
                  style={{ marginBottom: "15px" }}
                />
                {error && state.message === "" ? (
                  <label className="errorMsg">Message can't be Empty</label>
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>
            <Button className="btn-sky-blue" type="submit">
              Post
            </Button>
            {isOpened && (
              <div className="SuccessFormMessage">
                Query Submitted Successfully!
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Comments;
