import React from "react";
import { Modal } from "react-bootstrap";
import video from "./assets/javahome-introduction-video.mp4";
import "./video.css";

export default function YoutubePlayer({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered className="video-modal">
      <Modal.Body className="video-modal-body">
        <div className="video-container">
          <div className="video-wrapper">
            <video className="video-element" controls autoPlay>
              <source src={video} type="video/mp4" />
            </video>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// To play from link
// url="https://drive.google.com/uc?id=1cbChGlmRZN3bAZIASSj-9XZGbH7akz7l"
