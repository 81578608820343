import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CoursesList from "../CoursesList";
import PaginationBasic from "../pagination";
import TabsComponent from "../tabsComponent";
//import { tabsData } from "./constants";
import axios from "axios";
import AxiosClient from "../../js/axiosClient";
import BootcampCards from "../CoursesList/bootcampCards";
import { useMemo } from "react";

const axiosClient = new AxiosClient({});
const cloneCopy = (data) => JSON.parse(JSON.stringify(data));

function FilterTabs({ page, selectedTabItem, searchQuery, setSearchQuery }) {
  const [results, setResults] = useState([]);
  const [tabsData, setTabsData] = useState([]);
  const [tabTitle, setTabTitle] = useState([]);

  const getAllData = async () => {
    try {
      const response = await axiosClient.request({
        url: process.env.REACT_APP_API_BASE_URL + "/v1/courses",
        method: "GET",
        headers: {
          "X-Channel": "app",
        },
      });
      if (response.data) {
        let courseCategorySet = new Set();
        response.data.courses.map((course, idx) => {
          courseCategorySet.add(course.category);
        });
        let dataTabs = [];
        let allCoursesList = { title: "All", courses: response.data.courses };
        dataTabs.push(allCoursesList);
        Array.from(courseCategorySet).map((category) => {
          let filteredCoursesList = {
            title: category,
            courses: response.data.courses.filter(
              (x) => x.category === category
            ),
          };
          dataTabs.push(filteredCoursesList);
        });
        setResults(response.data);
        setTabsData(dataTabs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCachedAllData = useMemo(() => getAllData, []);

  useEffect(() => {
    getAllData();
  }, []);
  const [toggleState, setToggleState] = useState(1);
  const [selectedTab, setSelectedTab] = useState([]);

  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const toggleTab = (index) => {
    setToggleState(index);
    setSelectedTab(courses[index - 1].courses);
    setTabTitle(courses[index - 1].courses);
  };

  useEffect(() => {
    if (tabsData.length > 0) {
      if (page === "courses") {
        const cloneData = cloneCopy(tabsData);
        setSelectedTab(cloneData[0].courses);
        setCourses(cloneData);
      } else {
        const cloneCourses = cloneCopy(tabsData).map((item) => {
          item.courses = item.courses.slice(0, 3);
          return item;
        });
        setSelectedTab(cloneCourses[0].courses);
        setCourses(cloneCourses);
      }
    }
  }, [page, tabsData]);
  const handleSelectedCourse = (id) => {
    navigate(`/courses/${id}/details`);
  };
  return (
    <div className="tabes-panel">
      <div className="container">
        <TabsComponent
          courses={courses}
          toggleState={toggleState}
          toggleTab={toggleTab}
          selectedTabItem={selectedTabItem}
        />
        <div className="content-tabs">
          <CoursesList
            list={selectedTab}
            handleSelected={handleSelectedCourse}
            data={results}
            tabTitle={tabTitle}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </div>
        {/* {page === "courses" && (
          <div className="pagination-wrapper d-flex justify-content-center p-3">
            <PaginationBasic />
          </div>
        )} */}
        {/* <BootcampCards/> */}
      </div>
    </div>
  );
}

export default FilterTabs;
