import React from "react";
import Table from "react-bootstrap/Table";
import { ListGroup } from "react-bootstrap";
import { BATCHES_DATA, COURSE_LIST } from "./constants";

export default function UpcomingBatches() {
  return (
    <>
      <div id="batches" className="course-details">
        <div className="container">
          <div className="upcoming-schedule-training">
            <div className="upcoming-batch">
              <h2>Level Up Your Skills: Upcoming Weekday Training Batches</h2>
              <p className="des-txt">
                Unleash your inner tech pro with our brand new weekday training
                batches launching soon! Designed for busy schedules, these
                sessions kick off at a convenient 8:00 AM IST every weekday
                (Monday to Friday). Dive deep into in-demand skills like AWS,
                Python Full Stack, DevOps, and more (check our website for the
                full course list!). Each session is carefully structured for
                optimal learning (1-1.5 hours), blending theory with practical
                application to help you master cutting-edge tech and unlock
                exciting career possibilities. Don't miss out – invest in your
                future! Secure your spot in our upcoming batch and embark on
                your journey to tech mastery. Ready to join? Visit our website
                today and register! <br />
                <strong>Explore a wide range of in-demand courses:</strong>
              </p>
              <ListGroup horizontal className="course-list-group">
                {COURSE_LIST.map((course, index) => (
                  <ListGroup.Item key={index}>{course}</ListGroup.Item>
                ))}
              </ListGroup>
              <Table bordered className="UB-table">
                <tbody>
                  {BATCHES_DATA.map((batch, index) => (
                    <tr key={index}>
                      <td>{batch.date}</td>
                      <td>{batch.course}</td>
                      <td className="c-td">
                        <span className="c-week">{batch.weekDays}</span>
                        <span className="c-week-day">WEEK DAYS BATCH</span>
                      </td>
                      <td className="c-td">
                        <span className="c-time">{batch.time}</span>
                        <span className="c-session">
                          (Class {batch.sessionDuration}) Per Session
                        </span>
                        <button className="btn float-end">Get Free Demo</button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={4}>
                      Can't find batch you were looking for?{" "}
                      <button className="btn r-batch-btn">
                        Request for Batch
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
