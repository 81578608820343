import * as axios from "axios";
export default class AxiosClient {
  constructor({ axios: axiosInst }) {
    this.axios = axiosInst ? axiosInst : axios.default;
  }

  async request(opts) {
    // const session = await Auth.currentSession();
    // const authorization = `Bearer ${session.getIdToken().getJwtToken()}`;

    if (opts.headers) {
      // opts.headers["Authorization"] = authorization;

      opts.headers["X-Channel"] = "app";
    } else {
      opts.headers = {
        // Authorization: authorization,

        "X-Channel": "app",
      };
    }
    if (!opts.headers["Content-Type"]) {
      opts.headers["Content-Type"] = "application/json";
    }

    return this.axios.request(opts);
  }
  objectToQueryString = (obj) => {
    if (!obj) return "";
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return "?" + str.join("&");
  };
}
