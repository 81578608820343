import React from "react";
import Phone from "../../images/phone_icon.svg";
import Email from "../../images/email_icon.svg";
import Location from "../../images/location_icon.svg";
import { Link } from "react-router-dom";
import {
  FaFacebookSquare,
  FaYoutubeSquare,
  FaInstagramSquare,
  FaLinkedin,
} from "react-icons/fa";

const isDevEnvironment = process.env.REACT_APP_ENV === "dev";

const footerList = [
  {
    name: "Company",
    list: [
      { route: "/privacy-policy", routeName: "Privacy Policy" },
      { route: "/about-us", routeName: "About us" },
      ...(isDevEnvironment ? [{ route: "/", routeName: "Services" }] : []),
      // { route: "/", routeName: "Blog" },
      { route: "/workshop", routeName: "Workshop" },
    ],
  },
  {
    name: "Programs",
    list: [
      { route: "/courses", routeName: "Course" },
      { route: "/courses", routeName: "Offline Class" },
      { route: "/courses", routeName: "Online Class" },
      ...(isDevEnvironment
        ? [{ route: "/workshop", routeName: "Corporate Training" }]
        : []),
    ],
  },
  ...(isDevEnvironment
    ? [
        {
          name: "Links",
          list: [
            { route: "/", routeName: "Events" },
            { route: "/", routeName: "Gallery" },
            { route: "/", routeName: "FAQs" },
            // { route: "/", routeName: "case studies" },
          ],
        },
      ]
    : []),
  {
    name: "Support",
    list: [
      ...(isDevEnvironment
        ? [
            { route: "/courses", routeName: "Documentation" },
            { route: "/", routeName: "Interviews" },
            { route: "/", routeName: "Companies" },
          ]
        : []),

      { route: "/placements", routeName: "Placements" },
    ],
  },
];
export default function Footer() {
  return (
    <div id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row m-0">
            <div className="col-lg-2 col-md-6 col-sm-12 footer-contact">
              <h3>Contact</h3>
              <p>
                <span>
                  <img
                    src={Phone}
                    alt="phone"
                    style={{ paddingRight: "10px" }}
                    loading="lazy"
                  />
                  (91)9886611117
                </span>
                <br />
                <span>
                  <img
                    src={Email}
                    alt="Email"
                    style={{ paddingRight: "10px" }}
                    loading="lazy"
                  />
                  hr@javahome.in
                </span>
                <br />
                <img
                  src={Location}
                  alt="Location"
                  style={{ paddingRight: "10px" }}
                  loading="lazy"
                />
                <span className="address">
                  {" "}
                  2nd floor, # 85, SGR Dental College Rd, <br />
                  Kasavanahalli Village, Marathahalli, <br /> Bengaluru,
                  Karnataka 560037.
                </span>
              </p>
            </div>

            {footerList &&
              footerList.map(({ name, list }, idx) => (
                <div
                  className="col-lg-2 col-md-6 col-sm-12 footer-links"
                  key={idx}
                >
                  <h4>{name}</h4>
                  <ul>
                    {list &&
                      list.map(({ routeName, route }, index) => (
                        <li key={index}>
                          <Link to={route}>{routeName} </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container clearfix">
          <div className="copyright d-md-flex">
            <div className="me-md-auto text-center text-md-start">
              {new Date().getFullYear()} @ All rights reserved. Java Home Cloud
            </div>
            <div className="social-links text-center text-md-end pt-3 pt-md-0">
              <a
                href="https://www.facebook.com/javahomecloud/"
                target="_blank"
                className="facebook"
                aria-label="Visit Java Home Cloud Facebook Page"
              >
                <FaFacebookSquare />
              </a>
              <a
                href="https://youtube.com/javahomecloud/"
                target="_blank"
                className="youtube"
                aria-label="Visit Java Home Cloud YouTube Channel"
              >
                <FaYoutubeSquare />
              </a>
              <a
                href="https://www.instagram.com/javahomecloud/"
                target="_blank"
                className="instagram"
                aria-label="Follow Java Home Cloud on Instagram"
              >
                <FaInstagramSquare />
              </a>
              <a
                href="https://www.linkedin.com/company/javahomecloud/"
                target="_blank"
                className="linkedin"
                aria-label="Connect with Java Home Cloud on LinkedIn"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
