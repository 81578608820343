import React, { useEffect } from "react";
import WorkshopTeam from "../../components/workshopTeam";
import AboutUsImg from "../../images/about-us-content-img.png";
import profileIcon from "../../images/Harish-img.jpg";
import Admission from "../../images/admission-img.png";
import ReactGA from "react-ga4";
export default function AboutUs() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="about-us">
      <div className="sub-banner">
        <h1 className="h1-large">
          Welcome to the JAVA HOME CLOUD <br /> online learning/offline learning
        </h1>
      </div>
      <div className="sub-banner-white">
        <h3 className="h3-medium">
          Our trainings helped lots of freshers & Experienced professionals to
          get jobs.
        </h3>
      </div>
      <div className="about-javahome content-bg-color">
        <div className="container">
          <div className="common-block">
            <div className="row">
              <div className="col-lg-7 col-xl-7">
                <h2>At Java Home Cloud </h2>
                <p className="des-txt">
                  We're passionate about empowering individuals and
                  organizations to achieve their full potential. We do this by
                  providing high-quality training and education in various
                  fields. Our team of experienced trainers is dedicated to your
                  success. They use practical, hands-on approaches to ensure
                  you're well-prepared to hit the ground running in the real
                  world.
                </p>
                <h3>Here's Why Java Home Cloud Stands Out:</h3>
                <ul>
                  <li>
                    Our trainers are seasoned professionals who bring real-world
                    experience directly to your learning journey.
                  </li>
                  <li>
                    Choose the path that fits your lifestyle! We offer flexible
                    online and in-person training options.
                  </li>
                  <li>
                    Our hands-on training incorporates real-world projects and
                    case studies
                  </li>
                  <li>
                    We're here to support you every step of the way. Get
                    personalized guidance and interview preparation to help you
                    land your dream job.
                  </li>
                  <li>
                    Invest in your future with our affordable courses! We offer
                    flexible payment options to, ensuring you get the skills you
                    need to succeed.
                  </li>
                </ul>

                <h3>Empowering You for Success</h3>
                <p className="des-txt">
                  At Java Home Cloud, we believe in unlocking your full
                  potential. We do this by providing high-quality training
                  programs that equip you with the knowledge, practical skills,
                  and in-demand tools you need to thrive in today's job market.
                </p>

                <h3>Building Bridges to Career Success </h3>
                <p className="des-txt">
                  We understand that traditional education may not always keep
                  pace with the evolving needs of the industry. That's where
                  Java Home Cloud steps in. Our STEP course bridges the gap by
                  equipping you with the practical skills and real-world
                  knowledge sought after by today's employers.
                </p>

                <h3>Why Choose us:</h3>
                <b>Industry-Relevant Skills:</b>
                <p className="des-txt">
                  We focus on practical skills and relevant knowledge to ensure
                  you're prepared for the real world.
                </p>
                <b>Expert Instruction: </b>
                <p className="des-txt">
                  Our team of experienced trainers brings their industry
                  expertise to the classroom.
                </p>
                <b>High-Quality Resources:</b>
                <p className="des-txt">
                  We leverage the best resources available to deliver
                  exceptional training programs.
                </p>

                
              </div>
              <div className="col-lg-5 col-xl-5">
                <img
                  src={AboutUsImg}
                  alt="About us"
                  className="img-fluid"
                  loading="lazy"
                />
                
                <h3 className="mt-3">Investing in Your Future </h3>
                <p className="des-txt">
                  By investing in your education with Java Home Cloud, you're
                  investing in your future career success. Our programs can help
                  you:
                </p>
                <b>Boost Your Employability:</b>
                <p className="des-txt">
                  Stand out from the crowd with in-demand skills.
                </p>
                <b>Enhance Your Career Prospects:</b>
                <p className="des-txt">
                  Open doors to new opportunities and career advancement.
                </p>
                <b>Gain Confidence: </b>
                <p className="des-txt">
                  Master the skills to succeed in any job market.
                </p>

                <h3>Ready to take the next step? </h3>
                <p className="des-txt">
                  Java Home Cloud is here to support you on your journey.
                  Contact us today to learn more about our STEP course and how
                  we can help you achieve your career goals.
                </p>

                {/* --------------- second option ----------------- */}
              </div>
            </div>
          </div>
        </div>
        <div className="common-block common-block-2">
          <div className="row m-0">
            <div className="col-lg-6 col-xl-6 ps-0">
              <img
                src={Admission}
                alt="Admission"
                className="Admission img-fluid"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 col-xl-6 adminssion-year">
              <h2 className="mt-2">Admission Open for {new Date().getFullYear()}</h2>
              <p className="des-txt">
                Are you a driven professional seeking to advance your career?
                Our meticulously designed courses are perfect for individuals
                like you. We empower you to develop the skills and knowledge
                needed to earn valuable certifications that will elevate your
                profile and expertise.
              </p>
              <p className="des-txt">
                For over 5 years, we've built a reputation for empowering
                individuals to achieve their career goals. We don't just offer
                training; we partner with you to develop a sustainable career
                path.
              </p>
              <b className="mb-1 mt-0 sub-title">
                Join us and unlock your full potential!
              </b>
              <a className="btn mt-2 mb-1" href="#contact">
                Enquiry Now
              </a>
              <h2 className="mt-3">
                Education for All: Your Success Story Starts Here
              </h2>
              <p className="des-txt">
                At Java Home Cloud, we're passionate about making quality
                education accessible. That's why we offer affordable, top-notch
                training. Our unique approach combines innovative course
                creation with industry expert collaboration, ensuring you gain
                relevant skills. But learning doesn't stop there! We provide
                lifetime access to materials and ongoing support, empowering
                your success beyond training. Mock interviews and resume help
                prepare you to land your dream job, making Java Home Cloud your
                one-stop shop for achieving your career goals.
              </p>
            </div>
          </div>
        </div>
        <div className="mission-vision-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3>Our Mission</h3>
                <p>
                  Empowering your education journey is our mission at Java Home
                  Cloud. Our engaging live online courses offer a flexible
                  option for students to continue and complete studies.
                </p>
                <p>
                  What sets us apart? Exceptional commitment! Our passionate
                  educators and 24/7 support ensure a supportive learning
                  environment. We're the fastest-growing platform with the
                  highest course completion rate because we don't give up on
                  you. Personalized guidance helps you navigate challenges and
                  achieve your career goals. Join us and unlock your full
                  potential!
                </p>
              </div>
              <div className="col-lg-6 pe-0 ps-0 mobile_vision">
                <h3>Vision: Democratization of the Educational Sector</h3>
                <p>
                  “We believe that everyone has the right to a quality
                  education, so we strive to provide quality training at the
                  lowest cost.”
                </p>

                <div className="vision_container">
                  <div className="vision_box">
                    <div className="vision_text">High Quality Content</div>
                    <div className="vision_text">Training pedagogy</div>
                    <div className="vision_text">24/7 support</div>
                    <div className="vision_text">Labor assistance</div>
                  </div>
                </div>
                <p>
                  Java Home Cloud combines a unique approach to ideation and
                  creation of course content. It then collaborates with SMEs for
                  training. In aliition, it offers its students lifetime support
                  and lifetime access to course materials.
                </p>
                <p>
                  It also provides professional help to ensure students find
                  lucrative jobs by conducting mock interview sessions and
                  helping each candidate create a high-quality resume.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="team_member_info">
            <h3>Expert Instructors</h3>
            <WorkshopTeam />
          </div>
        </div>
      </div>
    </div>
  );
}
