import React from "react";

// import TrustedBy from "../../components/trusted-by";
// import TrustedByInPlacement from "../../components/trusted-by/clientblockinplacement";
import TrustedByInPlacement from "./components/clientblockinplacement";
import PlacementDescription from "./components/PlacementDescription";
import RecentlyPlacedStudents from "./components/recentlyPlacedStudents";
import Features from "./components/Features";

import Tata from "../../images/Tata Elxsi.svg";
import Nissan from "../../images/Nissan.svg";
import Infosys from "../../images/Infosys.svg";
import Hcl from "../../images/HCL.svg";
// import Accenture from "../../images/Accenture.svg";

const trustedData = [
  { title: "TATA", imgSrc: Tata },
  { title: "Nissan", imgSrc: Nissan },
  { title: "Infosys", imgSrc: Infosys },
  { title: "HCL", imgSrc: Hcl },
  // { title: "Accenture", imgSrc: Accenture },
];

export default function Placements() {
  return (
    <div className="placement-page">
      <div className="sub-banner">
        <h1 className="h1-large">Our Placement Approach</h1>
      </div>
      {/* <TrustedBy trustedData={trustedData} /> */}
      <TrustedByInPlacement trustedData={trustedData} />
      <div className="placement-details">
        <div className="container">
          <PlacementDescription />
          <div className="recent-placed-students">
            <RecentlyPlacedStudents />
            <Features />
          </div>
        </div>
      </div>
    </div>
  );
}
