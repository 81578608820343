import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import AxiosClient from "../../js/axiosClient";
import "react-phone-input-2/lib/style.css";

const axiosClient = new AxiosClient({});

const BookYourSlot = ({
  page,
  selectedTabItem,
  title,
  subTitle,
  showModal,
  handleClose,
  coursename,
}) => {
  const [tabsData, setTabsData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedTab, setSelectedTab] = useState([]);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    courseName: "", // Initialize courseName as empty
  });
  const [error, setError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const getCourseDetails = async () => {
    try {
      const response = await axiosClient.request({
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/courses`,
        method: "GET",
        headers: { "X-Channel": "app" },
      });
      if (response.data) {
        const dataTabs = buildTabsData(response.data.courses);
        setTabsData(dataTabs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buildTabsData = (courses) => {
    let courseCategorySet = new Set();
    courses.forEach((course) => courseCategorySet.add(course.category));

    let dataTabs = [{ title: "All", courses }];
    Array.from(courseCategorySet).forEach((category) => {
      dataTabs.push({
        title: category,
        courses: courses.filter((course) => course.category === category),
      });
    });

    return dataTabs;
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  useEffect(() => {
    if (coursename) {
      setFormState((prevState) => ({
        ...prevState,
        courseName: coursename,
      }));
    }
  }, [coursename]);

  useEffect(() => {
    if (tabsData.length > 0) {
      const filteredCourses =
        page === "courses"
          ? tabsData
          : tabsData.map((tab) => ({
              ...tab,
              courses: tab.courses.slice(0, 3),
            }));
      setCourses(filteredCourses);
      setSelectedTab(filteredCourses[0]?.courses || []);
    }
  }, [page, tabsData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePhoneInputChange = (phoneNumber) => {
    setFormState((prevState) => ({ ...prevState, phoneNumber }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email, phoneNumber, courseName, message } = formState;

    if (
      !name ||
      !email ||
      !phoneNumber ||
      !courseName ||
      !message ||
      !/^\S+@\S+\.\S+$/.test(email)
    ) {
      setError(true);
      return;
    }

    try {
      await axiosClient.request({
        url: `${process.env.REACT_APP_API_BASE_URL}/v1/enquiryNow`,
        method: "POST",
        headers: {
          "X-Channel": "app",
          "content-type": "application/json",
        },
        data: formState,
      });
      setIsOpened(true);
      setFormSubmitted(true);
      setError(false);
      resetForm();
    } catch (error) {
      console.error("Something went wrong!", error);
    }
  };

  const resetForm = () => {
    setTimeout(() => {
      setFormState({
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
        courseName: "",
      });
      setIsOpened(false);
      setFormSubmitted(false);
      handleClose();
    }, 6000);
  };

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="bys_body no-backdrop"
      >
        <div className="bys_card">
          <Modal.Header className="bg-white text-center p-2">
            <Modal.Title className="bys_heading">Book Your Slot</Modal.Title>
            <button onClick={handleClose} className="bys-close-modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body className="bg-white p-3 bys_form">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="form-divider">
                <Form.Control
                  type="text"
                  placeholder="Your Name"
                  className="mb-1"
                  name="name"
                  value={formState.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="form-divider">
                <Form.Control
                  type="email"
                  placeholder="Your Email"
                  className="mb-1"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <PhoneInput
                country={"in"}
                enableSearch={true}
                countryCodeEditable={false}
                className="mb-3"
                value={formState.phoneNumber}
                onChange={handlePhoneInputChange}
                inputProps={{ name: "phoneNumber", required: true }}
                inputStyle={{ width: "100%" }}
                required
              />
              <Form.Group className="form-divider">
                <Form.Select
                  name="courseName"
                  className="mb-1"
                  value={formState.courseName}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a course</option>
                  {tabsData.length > 0 &&
                    tabsData[0].courses.map((course, idx) => (
                      <option
                        key={idx}
                        value={course.courseName}
                        selected={course.courseName === formState.courseName}
                      >
                        {course.courseName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-divider">
                <Form.Control
                  type="text"
                  placeholder="Message"
                  name="message"
                  className="mb-1"
                  value={formState.message}
                  onChange={handleChange}
                  as="textarea"
                  rows={2}
                  required
                />
              </Form.Group>
              <Button className="btn-sky-blue mb-1 bsy_btn" type="submit">
                Send Your Enquiry
              </Button>
              {isOpened && (
                <div className="SuccessFormMessage">
                  Book Your Slot Successfully!
                </div>
              )}
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default BookYourSlot;
