import React from "react";
import UnderConstruct from "../images/under-constraction.jpg";

export default function collageTraining() {
  return (
    <div className="about-us privacy_policy">
      <div className="sub-banner">
        <h1 className="h1-large">Collage Training</h1>
      </div>
      <div className="under_construction">
        <img src={UnderConstruct} alt="UnderConstruct" className="img-fluid" />
        <h2>Enter your e-mail below and we'll notice you when it is ready.</h2>
      </div>
    </div>
  );
}
