import React from "react";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";

export default function JenkinsYoutubePlayer({ show, handleClose, src }) {
  return (
    <Modal show={show} onHide={handleClose} className="youtube_player" centered>
      <Modal.Body>
        <ReactPlayer width="100%" playing={true} url={src} controls={true} />
      </Modal.Body>
    </Modal>
  );
}
