import React, { useEffect, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useLocation, useNavigate } from "react-router-dom";
import CoursesList from "../CoursesList";
import startsWith from "lodash.startswith";

import validator from "validator";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AxiosClient from "../../js/axiosClient";

const cloneCopy = (data) => JSON.parse(JSON.stringify(data));
const axiosClient = new AxiosClient({});
function ContactForm({ page, selectedTabItem, title, subTitle }) {
  //courses list
  const [tabTitle, setTabTitle] = useState([]);
  const [tabsData, setTabsData] = useState([]);
  const [name, setName] = useState([]);
  const [results, setResults] = useState("");
  const [error, setError] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const getCourseDetails = async () => {
    try {
      const response = await axiosClient.request({
        url: process.env.REACT_APP_API_BASE_URL + "/v1/courses",
        method: "GET",
        headers: {
          "X-Channel": "app",
        },
      });
      if (response.data) {
        setResults("results", response.data);
        let courseCategorySet = new Set();
        response.data.courses.map((course, idx) => {
          courseCategorySet.add(course.category);
        });
        let dataTabs = [];
        let allCoursesList = { title: "All", courses: response.data.courses };
        dataTabs.push(allCoursesList);
        Array.from(courseCategorySet).map((category) => {
          let filteredCoursesList = {
            title: category,
            courses: response.data.courses.filter(
              (x) => x.category === category
            ),
          };
          dataTabs.push(filteredCoursesList);
        });
        setResults(response.data);
        setName(response.data);
        setTabsData(dataTabs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCachedCourseDetails = useMemo(() => getCourseDetails, []);

  const [toggleState, setToggleState] = useState(1);
  const [selectedTab, setSelectedTab] = useState([]);

  const [courses, setCourses] = useState([]);
  const toggleTab = (index) => {
    setToggleState(index);
    setSelectedTab(courses[index - 1].courses);
    setTabTitle(courses[index - 1].courses);
  };
  useEffect(() => {
    if (tabsData.length > 0) {
      if (page === "courses") {
        const cloneData = cloneCopy(tabsData);
        setSelectedTab(cloneData[0].courses);
        setCourses(cloneData);
      } else {
        const cloneCourses = cloneCopy(tabsData).map((item) => {
          item.courses = item.courses.slice(0, 3);
          return item;
        });
        setSelectedTab(cloneCourses[0].courses);
        setCourses(cloneCourses);
      }
    }
  }, [page, tabsData]);
  useEffect(() => {
    getCachedCourseDetails();
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const [relatedCourses, setRelatedCourses] = useState(false);
  useEffect(
    (e) => {
      if (location.pathname.includes("/details")) {
        setRelatedCourses(true);
      } else {
        setRelatedCourses(false);
      }
    },
    [location]
  );
  const handleSelectedCourse = (id, e) => {
    navigate(`/courses/${id}/details`);
    //window.location.reload(false);
  };

  //Enquiry form
  const [state, setState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
    courseName: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };
  const handlePhoneInputChange = (e) => {
    setState({
      ...state,
      ["phoneNumber"]: e,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const email = event.target.email.value;
    const phoneNumber = event.target.phoneNumber.value;
    const courseName = event.target.courseName.value;
    const message = event.target.message.value;

    if (name.trim() === "") {
      setError(true);
      return;
    }
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setError(true);
      return;
    }

    if (phoneNumber.trim() === "") {
      setError(true);
      return;
    }
    if (courseName.trim() === "") {
      setError(true);
      return;
    }
    if (message.trim() === "") {
      setError(true);
      return;
    }
    const userData = {
      name: state.name,
      email: state.email,
      phoneNumber: state.phoneNumber,
      message: state.message,
      courseName: state.courseName,
    };
    //axios;
    let url = process.env.REACT_APP_API_BASE_URL +"/v1/enquiryNow";
    let data = userData;
    axiosClient
      .request({
        url: url,
        method: "POST",
        headers: {
          "X-Channel": "app",
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
        data: data,
      })
      .then((resp) => {
        setIsOpened((wasOpened) => !wasOpened);
        setFormSubmitted(true);
        setError(false);
        setTimeout(() => {
          setState({
            name: "",
            email: "",
            phoneNumber: "91",
            message: "",
            courseName: "",
          });
          setIsOpened(false);
          setError(false);
          setFormSubmitted(false);
        }, [6000]);
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  useEffect(() => {
    setFormSubmitted(false);
  }, [formSubmitted]);

  return (
    <React.Fragment>
      {relatedCourses && (
        <div className="container my-5">
          <div className="text-center rl_course">
            <h2>Related courses</h2>
          </div>
          <CoursesList
            list={selectedTabItem}
            handleSelected={handleSelectedCourse}
            data={results}
            tabTitle={tabTitle}
          />
        </div>
      )}
      <section id="contact">
        <div className="contact-form">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="contact-text-container">
                  <h2 className="h1-large text-start w-100">
                    Upskill and get certified! Explore our diverse course
                    offerings and take your career to the next level.
                  </h2>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-container">
                  <h6>Send your Queries</h6>
                  <h2>Get In Touch</h2>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="form-divider">
                      <Form.Control
                        type="name"
                        placeholder="Your Name"
                        name="name"
                        value={state.name}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    {error && state.name === "" ? (
                      <label className="errorMsg">Name can't be Empty</label>
                    ) : (
                      ""
                    )}

                    <Form.Group className="form-divider">
                      <Form.Control
                        type="email"
                        placeholder="Your Email"
                        name="email"
                        value={state.email}
                        onChange={(e) => handleChange(e)}
                      />
                    </Form.Group>
                    {error && state.email === "" ? (
                      <label className="errorMsg">Email can't be Empty</label>
                    ) : (
                      ""
                    )}
                    {/* <Form.Group className="form-divider">
                    <Form.Control
                      type="text"
                      placeholder="Enter Your number"
                      name="phoneNumber"
                      value={state.phoneNumber}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Group> */}

                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      countryCodeEditable={false}
                      onChange={handlePhoneInputChange}
                      separateDialCode={true}
                      inputProps={{
                        name: "phoneNumber",
                      }}
                      value={state.phoneNumber}
                    />

                    {error && state.phoneNumber === "" ? (
                      <label className="errorMsg">
                        Phone number can't be Empty
                      </label>
                    ) : (
                      ""
                    )}

                    <Form.Group className="form-divider">
                      <Form.Select
                        className="form-control"
                        name="courseName"
                        value={state.courseName}
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select Course</option>
                        {name.courses &&
                          name.courses.map((item, idx) => (
                            <option key={idx}>{item.courseName}</option>
                          ))}
                      </Form.Select>
                    </Form.Group>
                    {error && state.courseName === "" ? (
                      <label className="errorMsg">Please select course</label>
                    ) : (
                      ""
                    )}
                    <Form.Group className="form-divider">
                      <Form.Control
                        className="form-message"
                        type="message"
                        placeholder="Message"
                        name="message"
                        value={state.message}
                        onChange={(e) => handleChange(e)}
                        as="textarea"
                        rows={2}
                      />
                    </Form.Group>
                    {error && state.message === "" ? (
                      <label className="errorMsg">Message can't be Empty</label>
                    ) : (
                      ""
                    )}
                    <Button
                      className="btn-sky-blue"
                      type="submit"
                      // onClick={onButtonClick}
                      style={{ position: "relative" }}
                    >
                      Send Your Enquiry
                    </Button>
                    {isOpened && (
                      <div className="SuccessFormMessage">
                        Query Submitted Successfully!
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ContactForm;
