import React, { useCallback, useEffect, useState } from "react";
import { eventContent, tabsData } from "./constants";
import TabsComponent from "../../components/tabsComponent";
import { cloneCopy } from "../../util";
import PlacementImage from "../../images/PlacementImage.jpg";
//import EventOne from "../../images/social-a1.png";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import "react-datepicker/dist/react-datepicker.css";
import Accordion from "react-bootstrap/Accordion";
import {
  FaSearch,
  FaRegThumbsUp,
  FaRegThumbsDown,
  FaRegCalendar,
  FaRegClock,
} from "react-icons/fa";
import ProgressLineBar from "../../components/progress-bar/ProgressLineBar";
import RatingReview from "../../components/rating-review/rating";
import Comments from "../../components/comments";
import "./workshop.css";

export default function Workshop() {
  const [startDate, setStartDate] = useState(new Date());
  const [toggleState, setToggleState] = useState(1);
  const [selectedTab, setSelectedTab] = useState(tabsData[0].content);
  const [courses, setCourses] = useState();
  const navigate = useNavigate();
  const [eventContentList, setEventContentList] = useState(eventContent);
  useEffect(() => {
    const cloneData = cloneCopy(tabsData);
    setSelectedTab(cloneData[0].content);
    setCourses(cloneData);
  }, []);
  const toggleTab = useCallback((index) => {
    setToggleState(index);
    setSelectedTab(tabsData[index - 1].content);
  }, []);
  const handleSelected = (id) => {
    navigate(`/workshop/${id}/details`);
  };

  const [activeEventKey, setActiveEventKey] = useState(0);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <div className="workshop-page">
      <div className="sub-banner">
        <h1 className="h1-large">
          Elevate Your Knowledge With Our Online Workshops
        </h1>
      </div>
      <div className="workshop-tabs">
        <div className="container">
          <TabsComponent
            courses={courses}
            toggleState={toggleState}
            toggleTab={toggleTab}
          />
          <div className="content-tabs">
            <div className="content active-content">
              {toggleState === 1 && (
                <div className="row">
                  <div className="col-lg-8">
                    {eventContentList &&
                      eventContentList.map(
                        (
                          {
                            title,
                            subTitle,
                            eventImg,
                            status,
                            date,
                            time,
                            // btnValue,
                          },
                          idx
                        ) => (
                          <div
                            className="event-card"
                            key={idx}
                            onClick={() => handleSelected(status)}
                          >
                            <div className="left_side_event">
                              <img
                                src={eventImg}
                                alt="event-img"
                                loading="lazy"
                              />
                              <button
                                className={
                                  "btn " +
                                  (status === "Upcoming"
                                    ? "upcoming"
                                    : "completed")
                                }
                              >
                                {status}
                              </button>
                            </div>
                            <div className="right_side_event" key={idx}>
                              <h3>{title}</h3>
                              <span>{subTitle}</span>
                              <div className=" timer  d-flex align-items-center tabs-flex ">
                                <p className="d-flex align-items-center mb-0 me-4 fw-medium date">
                                  <FaRegCalendar />
                                  <div className="date_time mx-2">{date}</div>
                                </p>
                                <p className="d-flex align-items-center mb-0 fw-medium timer">
                                  <FaRegClock />
                                  <div className="date_time mx-2">{time}</div>
                                </p>
                                {/* <button className="btn btn-purple ms-auto">
                                  {btnValue}
                                </button> */}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                  <div className="col-lg-4">
                    <div className="find_workshop">
                      <h3>Find Workshop</h3>
                      <div className="diff_date_style date_picker">
                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                        <span>
                          <FaRegCalendar />
                        </span>
                      </div>
                      <div className="form-container keyword_select">
                        <label for="courseName" class="visually-hidden">
                          Select a Course:
                        </label>
                        <select
                          name="courseName"
                          id="courseName"
                          className="form-control form-select"
                        >
                          <option value="Course">Key Words</option>
                          <option value="AWS">AWS</option>
                          <option value="Java">Java</option>
                          <option value="React JS">React JS</option>
                          <option value="Angular JS">Angular JS</option>
                        </select>
                      </div>
                      <div className="input_search">
                        <input
                          className="search-input-box"
                          placeholder="Search"
                          aria-label="Search"
                        />
                        <span>
                          <FaSearch />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {toggleState === 2 && (
                <div className="row">
                  {/* <div className="tab-accordions">
                    <div className="tab-accord">
                      <h2>Course Content</h2>
                    </div>
                    <Accordion defaultActiveKey="0"></Accordion>
                  </div> */}
                </div>
              )}
              {toggleState === 3 && (
                <div className="row">
                  {/* <div className="common-block-3">
                    <div className="common-crs-details">
                      <h5 className="border-bottom pb-4 mb-4">Instructor</h5>
                      <div className="d-flex b-bottom pb-4 mb-4">
                        <div className="common-img-2">
                          <img
                            className="img-fluid rounded-circle"
                            src={PlacementImage}
                            alt="placement"
                          />
                        </div>
                        <div className="ms-3">
                          <h5>THIMPRESS</h5>
                          <h6 className="text-h6">Developer</h6>
                          <p className="des-text mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. In molestie, mauris quis dapibus lobortis,
                            mauris enim faucibus quam, eget malesuada ex nisi eu
                            felis. Vivamus dapibus, quam vel porta rhoncus, orci
                            massa eleifend velit, sit amet vehicula odio tellus
                            in magna. Cras et posuere diam. Aliquam suscipit
                            dolor sit amet mauris rhoncus feugiat. Morbi ac diam
                            ut purus dapibus.
                          </p>
                          <div className="course-content-1">
                            <ul>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex b-bottom pb-4 mb-4">
                        <div className="common-img-2">
                          <img
                            className="img-fluid rounded-circle"
                            src={PlacementImage}
                            alt="placement"
                          />
                        </div>
                        <div className="ms-3">
                          <h5>THIMPRESS</h5>
                          <h6 className="text-h6">Developer</h6>
                          <p className="des-text mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. In molestie, mauris quis dapibus lobortis,
                            mauris enim faucibus quam, eget malesuada ex nisi eu
                            felis. Vivamus dapibus, quam vel porta rhoncus, orci
                            massa eleifend velit, sit amet vehicula odio tellus
                            in magna. Cras et posuere diam. Aliquam suscipit
                            dolor sit amet mauris rhoncus feugiat. Morbi ac diam
                            ut purus dapibus.
                          </p>
                          <div className="course-content-1">
                            <ul>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex b-bottom">
                        <div className="common-img-2">
                          <img
                            className="img-fluid rounded-circle"
                            src={PlacementImage}
                            alt="placement"
                          />
                        </div>
                        <div className="ms-3">
                          <h5>THIMPRESS</h5>
                          <h6 className="text-h6">Developer</h6>
                          <p className="des-text mb-4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. In molestie, mauris quis dapibus lobortis,
                            mauris enim faucibus quam, eget malesuada ex nisi eu
                            felis. Vivamus dapibus, quam vel porta rhoncus, orci
                            massa eleifend velit, sit amet vehicula odio tellus
                            in magna. Cras et posuere diam. Aliquam suscipit
                            dolor sit amet mauris rhoncus feugiat. Morbi ac diam
                            ut purus dapibus.
                          </p>
                          <div className="course-content-1">
                            <ul>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                              <li>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              )}
              {toggleState === 4 && (
                <div className="row">
                  {/* <div className="tab-accordions faq-accordions">
                    <div className="tab-accord">
                      <h2>Frequently Asked Questions</h2>
                    </div>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0" className="tab-accord">
                        <Accordion.Header>
                          <h5>What are the benefits of an AWS?</h5>
                          <div className="faq-thumb">
                            <button type="button" className="btn-none">
                              <FaRegThumbsUp /> 11
                            </button>
                            <button type="button" className="btn-none">
                              <FaRegThumbsDown /> 0
                            </button>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            An LMS is short for Learning Management System. It
                            is a software that allows you to automate, manage,
                            and capture the learning, or training, with your
                            organization. An LMS, or learning management system,
                            combines a wide range of features to present.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className="tab-accord">
                        <Accordion.Header>
                          <h5>What are the benefits of an AWS?</h5>
                          <div className="faq-thumb">
                            <button type="button" className="btn-none">
                              <FaRegThumbsUp /> 11
                            </button>
                            <button type="button" className="btn-none">
                              <FaRegThumbsDown /> 0
                            </button>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            An LMS is short for Learning Management System. It
                            is a software that allows you to automate, manage,
                            and capture the learning, or training, with your
                            organization. An LMS, or learning management system,
                            combines a wide range of features to present.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2" className="tab-accord">
                        <Accordion.Header>
                          <h5>What are the benefits of an AWS?</h5>
                          <div className="faq-thumb">
                            <button type="button" className="btn-none">
                              <FaRegThumbsUp /> 11
                            </button>
                            <button type="button" className="btn-none">
                              <FaRegThumbsDown /> 0
                            </button>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            An LMS is short for Learning Management System. It
                            is a software that allows you to automate, manage,
                            and capture the learning, or training, with your
                            organization. An LMS, or learning management system,
                            combines a wide range of features to present.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" className="tab-accord">
                        <Accordion.Header>
                          <h5>What are the benefits of an AWS?</h5>
                          <div className="faq-thumb">
                            <button type="button" className="btn-none">
                              <FaRegThumbsUp /> 11
                            </button>
                            <button type="button" className="btn-none">
                              <FaRegThumbsDown /> 0
                            </button>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            An LMS is short for Learning Management System. It
                            is a software that allows you to automate, manage,
                            and capture the learning, or training, with your
                            organization. An LMS, or learning management system,
                            combines a wide range of features to present.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div> */}
                </div>
              )}
              {toggleState === 5 && (
                <>
                  {/* <div className="row progress_rating mx-0">
                    <div className="col-lg-3">
                      <RatingReview />
                    </div>
                    <div className="col-lg-9">
                      <ProgressLineBar />
                    </div>
                  </div>

                  <div className="row comment_container mx-0">
                    <div className="col-lg-12">
                      <Comments />
                    </div>
                  </div> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
