import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import SocialService from "../../images/social-service.png";
import socialbooksimg from "../../images/social-book-img.webp";
import socialbooksimg2 from "../../images/social-booke-img2.webp";
import socialTechnology from "../../images/social-technology-education.jpeg";
import socialDonating from "../../images/social-donate-img.jpg";
import socialEducation from "../../images/social-education-img.jpg";
import socialEducation2 from "../../images/social-education-2.jpg";
import socialTeacher from "../../images/social-teacher-img.jpg";

import { Link, useParams } from "react-router-dom";

const socialActivityData = [
  {
    id: 1,
    title: "What children love about books",
    img1: socialbooksimg,
    img2: socialbooksimg2,
    details: [
      "Children have vivid imaginations, and books fuel that creative power. Whether they are reading about magical kingdoms, space adventures, or talking animals, books give children the freedom to imagine worlds that are larger than life. The words on the page help paint pictures in their minds, sparking creativity. Through stories, they can travel to places theyve never been, meet characters that are out of this world, and go on adventures that would otherwise be impossible in real life.",
    ],

    details2: [
      "Through fun characters and interesting narratives, they absorb knowledge naturally. Educational topics like history, science, and even moral values become entertaining and easy to understand through storytelling.",
    ],
    details3: [
      " Whether it’s a brave hero, a kind friend, or even a mischievous character, children often see parts of themselves in the stories they read. These characters become their companions, helping them understand emotions.",
    ],
    details4: [
      "Whether it’s finishing a chapter book for the first time or understanding a complex story, they develop confidence in their abilities. For younger children, flipping through the pages of a book or learning new words brings a sense of pride and achievement.",
    ],
  },
  {
    id: 2,
    title: "Provide resources and educational materials",
    img1: socialTechnology,
    img2: socialTeacher,
    details: [
      "   Providing educational resources and materials is a crucial element in ensuring that children and learners of all ages have access to quality education and the tools they need to succeed. These resources can come in many forms, including textbooks, online courses, interactive learning tools, apps, and even hands-on learning experiences. When educational resources are accessible, they help bridge the gap between learners from various socio-economic backgrounds and create equal opportunities for learning. ",
    ],
    details2: [
      "One of the most important aspects of providing educational resources is ensuring that children, especially those in underprivileged areas, have access to knowledge. In many parts of the world, students don't have access to the books, internet.",
    ],
    details3: [
      " Learning Apps: Digital apps tailored to different subjects and age groups.Online Courses: Free or affordable courses that students can access to learn new topics.Videos and Interactive Content: Educational videos that explain complex concepts in engaging ways.",
    ],
    details4: [
      "Providing a variety of educational materials ensures that the needs of all learners are met. Here’s how this can be broken down:Visual Learners: Benefit from videos, infographics, charts, and diagrams.",
    ],
  },

  {
    id: 3,
    title: "Our book donation program is empowering children to develop",
    img1: socialDonating,
    img2: SocialService,
    details: [
      " Book donation programs are powerful tools for empowering children and communities, especially in underserved regions where access to educational materials is limited. Books play a crucial role in a child’s cognitive and emotional development, and having access to books can significantly improve literacy, creativity, and curiosity in young minds.  This independence in learning fosters a sense of accomplishment and encourages them to take on new challenges.",
    ],
    details2: [
      " A well-organized book donation program not only helps children learn to read but also empowers them to expand their horizons and develop important life skills.Develop Basic Literacy Skills: For many children in low-income or remote areas.",
    ],
    details3: [
      " Learning to read is the foundation of education, and once children can read, they are better equipped to understand other subjects like math, science, and history.",
    ],
    details4: [
      "This expands their creative thinking and helps them understand that the world is full of possibilities.Explore New Interests: Children can use books to dive into subjects they’re curious about.",
    ],
  },

  {
    id: 4,
    title: "Learning and reach their full potential",
    img1: socialEducation,
    img2: socialEducation2,
    details: [
      " Foundational Knowledge: Learning provides children with basic skills, like reading, writing, and math, that are crucial for understanding more complex subjects as they grow. Mastery of these skills is the foundation for lifelong learning, ensuring they can engage in various academic, vocational, and personal pursuits. This ability helps children handle social situations, develop empathy, and build healthy relationships.Resilience: Learning involves challenges, mistakes, and perseverance. ",
    ],
    details2: [
      " Schools teach children to analyze information, ask questions, and think logically. These skills are necessary for making decisions in everyday life and in their future careers.Curiosity and Exploration: Learning ignites curiosity.",
    ],
    details3: [
      " Curiosity helps them to stay engaged and motivated to learn more, broadening their understanding of the world and encouraging creativity.Self-Confidence: As children master new skills and knowledge.",
    ],
    details4: [
      "Critical Thinking: As children learn, they develop critical thinking and problem-solving abilities. Completing a challenging task or understanding a complex subject gives them confidence in their abilities.",
    ],
  },

  // Add other activity details here...
];

export default function SocialActivityDetails() {
  const { id } = useParams();
  const activity = socialActivityData.find((item) => item.id === Number(id));

  if (!activity) {
    return <div>Activity not found</div>;
  }

  

  return (
    <div className="workshop-detail-view">
      <div className="workshop-detail-sub-banner social_detail_view">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="workshop-detail-heading">
                <Breadcrumb>
                  <Breadcrumb.Item  linkAs={Link} linkProps={{ to: "/socialActivity" }}>
                    Social Activity
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{activity.title}</Breadcrumb.Item>
                </Breadcrumb>
                <h1 className="h1-large">{activity.title}</h1>
              </div>
              <div className="workshop-detail-banner">
                <img
                  src={activity.img1}
                  alt="social-detail-view"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="social-service">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p className="ws-text">
                  {activity.details.map((detail, index) => (
                    <p className="ws-text" key={index}>
                      {detail}
                    </p>
                  ))}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-7">
                <p className="ws-text">{activity.details2}</p>
                <p className="ws-text">{activity.details3}</p>
                <p className="ws-text">{activity.details4}</p>
              </div>
              <div className="col-lg-5">
                <img
                  src={activity.img2}
                  alt="SocialService"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
