import React, { useEffect, useState } from "react";
// import { workshopData } from "./constants";
import axios from "axios";
import StickyBox from "react-sticky-box";

export default function WorkshopTeam() {
  const url = process.env.REACT_APP_API_BASE_URL + "instructors";
  const [workshopData, setWorkshopData] = useState();
  const getAllData = async () => {
    try {
      // const url = process.env.REACT_APP_API_BASE_URL + `"courses/${params.courseId}"`;
      const response = await axios({
        url: url,
        method: "GET",
        headers: {
          "X-Channel": "app",
        },
      });
      if (response) {
        setWorkshopData([...response.data.instructors]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  return (
    <div className="team_member_detail">
      <StickyBox>
        <ui
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            listStyleType: "none",
            paddingLeft: "20px",
            flexWrap: "nowrap",
            height: "auto",
            justifyItems: "center",
          }}
        >
          {workshopData &&
            workshopData.map(({ photo, fullName, designation }, idx) => (
              <li className="team_profile" key={idx}>
                <img src={photo} alt="profileIcon" loading="lazy"/>
                <div className="tr_pr_detail">
                  <div className="tr_name">{fullName}</div>
                  <div className="tr_role">{designation}</div>
                </div>
              </li>
            ))}
        </ui>
      </StickyBox>
    </div>
  );
}
