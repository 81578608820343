import imgSrc1 from "../../images/workshop_1.jpg";
import imgSrc2 from "../../images/workshop_2.jpg";
import imgSrc3 from "../../images/workshop_3.jpg";
import imgSrc4 from "../../images/workshop_4.jpg";
import imgSrc5 from "../workshop/assits/WS1.jpg";
import imgSrc6 from "../workshop/assits/WS2.jpg";
import imgSrc7 from "../workshop/assits/WS3.jpg";
import imgSrc8 from "../workshop/assits/WS4.jpg";
import imgSrc9 from "../workshop/assits/WS5.jpg";
export const tabsData = [
  {
    title: "Events",
    content:
      "Events Lorem ipsum dolor sit amet, consectetur adipiscing elit. In molestie, mauris quis dapibus lobortis, mauris enim faucibus quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam vel porta rhoncus, orci massa eleifend velit, sit amet vehicula odio tellus in magna. Cras et posuere diam. Aliquam suscipit dolor sit amet mauris rhoncus feugiat. Morbi ac diam ut purus dapibus ultrices. Morbi venenatis accumsan elit vitae vestibulum. Vivamus mattis consectetur justo sed dictum. In viverra consequat felis vel mattis. Cras ut facilisis nulla, sit amet condimentum sem.",
  },
  {
    title: "Blogs",
    content:
      "Blogs Lorem ipsum dolor sit amet, consectetur adipiscing elit. In molestie, mauris quis dapibus lobortis, mauris enim faucibus quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam vel porta rhoncus, orci massa eleifend velit, sit amet vehicula odio tellus in magna. Cras et posuere diam. Aliquam suscipit dolor sit amet mauris rhoncus feugiat. Morbi ac diam ut purus dapibus ultrices. Morbi venenatis accumsan elit vitae vestibulum. Vivamus mattis consectetur justo sed dictum. In viverra consequat felis vel mattis. Cras ut facilisis nulla, sit amet condimentum sem.",
  },
  {
    title: "Case Studies",
    content:
      "Case Studies Lorem ipsum dolor sit amet, consectetur adipiscing elit. In molestie, mauris quis dapibus lobortis, mauris enim faucibus quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam vel porta rhoncus, orci massa eleifend velit, sit amet vehicula odio tellus in magna. Cras et posuere diam. Aliquam suscipit dolor sit amet mauris rhoncus feugiat. Morbi ac diam ut purus dapibus ultrices. Morbi venenatis accumsan elit vitae vestibulum. Vivamus mattis consectetur justo sed dictum. In viverra consequat felis vel mattis. Cras ut facilisis nulla, sit amet condimentum sem.",
  },
  {
    title: "Social Activities",
    content:
      "Social Activities Lorem ipsum dolor sit amet, consectetur adipiscing elit. In molestie, mauris quis dapibus lobortis, mauris enim faucibus quam, eget malesuada ex nisi eu felis. Vivamus dapibus, quam vel porta rhoncus, orci massa eleifend velit, sit amet vehicula odio tellus in magna. Cras et posuere diam. Aliquam suscipit dolor sit amet mauris rhoncus feugiat. Morbi ac diam ut purus dapibus ultrices. Morbi venenatis accumsan elit vitae vestibulum. Vivamus mattis consectetur justo sed dictum. In viverra consequat felis vel mattis. Cras ut facilisis nulla, sit amet condimentum sem.",
  },
];
export const eventContent = [
  
  {
    eventImg: imgSrc1,
    title: "Creating Futures Through Technology Conference",
    subTitle:
      "Unlimited access to world-class learning from your laptop, tablet, or ... Phone, join over 15,000+ students.",
    status: "Upcoming",
    date: "APRIL 1, 2024",
    time: "10.30 AM",
    // btnValue: "Book",
  },
  {
    eventImg: imgSrc9,
    title: "Serverless Deveploment",
    subTitle:
      "Gain boundless educational opportunities from your laptop, tablet, or smartphone. Join a community of over 17,000+ students.",
    // subTitle:
    //   " Serverless Workshop Empowering 10,000+ Students with Cutting-edge Skills and Innovation.",
    status: "Completed",
    date: "DEC 23, 2023",
    time: "10.30 AM",
    // btnValue: "View",
  },
  {
    eventImg: imgSrc3,
    title: "AGILE & JIRA Seminar",
    subTitle:
      "Access premium learning resources anytime, anywhere, whether on your laptop, tablet, or phone. Join a cohort of 14,000+ learners.",
    // subTitle:
    //   "Transformative Workshop Empowering 12,000+ Students to Master Efficient Project Management and Collaboration.",
    status: "Completed",
    date: "AUG 26, 2023",
    time: "10.30 AM",
    // btnValue: "View",
  },
  {
    eventImg: imgSrc8,
    title: "Log Management - Docker & Kubernets",
    subTitle:
      "Embark on a journey of limitless learning, accessible from your laptop, tablet, or smartphone. Join a thriving student body of over 16,600+.",
    // subTitle:
    //   " Mastering Log Management in Docker & Kubernetes Workshop. Join 15,000+ Students in Harnessing the Power of Centralized Logging for Enhanced Performance and Reliability.",
    status: "Completed",
    date: "JULY 24, 2023",
    time: "10.30 AM",
    // btnValue: "View",
  },
  {
    eventImg: imgSrc6,
    title: "Resume & Interview Preparation Tips and Tricks",
    subTitle:
      "Explore world-class education from the convenience of your laptop, tablet, or mobile device. Become part of a dynamic community of 20,000+ students.",
    // subTitle:
    //   "Mastering Resume & Interview Techniques for Success. Join 20,000+ Students in Unlocking Job Opportunities with Proven Tips and Tricks.",
    status: "Completed",
    date: "JULY 24, 2023",
    time: "10.30 AM",
    // btnValue: "View",
  },
  {
    eventImg: imgSrc5,
    title: "Gitlab - Seminar",
    subTitle:
      "Access top-tier education from any device: laptop, tablet, or phone. Join a cohort of 14,000+ students dedicated to expanding their knowledge.",
    // subTitle:
    //   "GitLab Seminar Workshop for 10,000+ Students – Transforming Skills, Enhancing Development, and Fostering Innovation.",
    status: "Completed",
    date: "JULY 24, 2023",
    time: "10.30 AM",
    // btnValue: "View",
  },
  {
    eventImg: imgSrc2,
    title: "Jenkins Pipeline Jobs Seminar",
    subTitle:
      "Engage in world-class learning from your laptop, tablet, or smartphone. Join a vibrant community of over 18,000+ students passionate about education.",
    // subTitle:
    //   "Mastering Jenkins Pipeline Jobs in a Workshop Empowering 15,000+ Students for Automated Continuous Integration and Delivery.",
    status: "Completed",
    date: "JUNE 24, 2023",
    time: "10.30 AM",
    // btnValue: "View",
  },
  {
    eventImg: imgSrc7,
    title: "Project Explation",
    subTitle:
      "Discover limitless learning possibilities from your laptop, tablet, or mobile. Join over 15,000+ students worldwide on their educational quest.",
    // subTitle:
    //   "Project Exploration Workshop Empowering 18,000+ Bright Minds to Create, Collaborate, and Transform Ideas into Reality.",
    status: "Completed",
    date: "APRIL 15, 2023",
    time: "10.30 AM",
    // btnValue: "View",
  },
  {
    eventImg: imgSrc4,
    title: "Aws & Devops Roles and Responsibilities",
    subTitle:
      "Unlock a world of knowledge from your laptop, tablet, or smartphone. Join a network of over 19,000+ students on their learning journey.",
    // subTitle:
    //   "A Comprehensive Workshop Unleashing the Roles and Responsibilities in Cloud Computing Excellence, Igniting the Potential of 15,000+ Students.",
    status: "Completed",
    date: "APRIL 29, 2023",
    time: "10.30 AM",
    // btnValue: "View",
  },
];
