import React, { useState, useEffect } from "react";
import { Carousel, Card } from "react-bootstrap";
import "./HomeReviewStyle.css";

import student1 from "./assets/testimonial-img1.png";
import student2 from "./assets/testimonial-img2.png";
import student3 from "./assets/testimonial-img3.png";
import student4 from "./assets/testimonial-img4.png";
import student5 from "./assets/testimonial-img5.png";
import student6 from "./assets/testimonial-img6.png";
import student7 from "./assets/testimonial-img7.png";
import student8 from "./assets/testimonial-img8.png";
import student9 from "./assets/testimonial-img9.png";
import student10 from "./assets/testimonial-img10.png";
import student11 from "./assets/testimonial-img11.png";
import student12 from "./assets/testimonial-img12.png";
import OpenDoubleqoute from "../../images/Open-Quote-HomeRevirewCarousel.svg";
import reviewLeftArrow from "../../images/Review-Carousel-Left-Arrow.png";
import reviewRightArrow from "../../images/Review-Carousel-Right-Arrow.png";

const reviewList = [
  {
    id: 1,
    name: "Diwakar",
    review:
      "The AWS and DevOps course exceeded my expectations! The hands-on labs were invaluable, and the instructors were knowledgeable. Highly recommended!",
    image: student1,
    company_name: "Wipro",
    skill: "AWS & DevOps",
  },
  {
    id: 2,
    name: "Raju",
    review:
      "The Python Full Stack Development course provided a solid foundation for both languages. The real-world projects were challenging and rewarding.",
    image: student2,
    company_name: "Mercedes Benz",
    skill: "Full Stack Development",
    // skill: "Aws & Devops",
  },
  {
    id: 3,
    name: "Bhaskar",
    review:
      "The Java/J2EE course was really helpful and hands-on, giving me solid skills for building powerful Java applications. Enjoyed the learning experience!",
    image: student3,
    company_name: "Cognizant",
    skill: "Java/J2EE",
  },
  {
    id: 4,
    name: "Krishna chaithanya",
    review:
      "React JS course was a game-changer for my front-end skills. The interactive sessions and project work made learning React enjoyable and effective.",
    image: student4,
    company_name: "Zensar Technologies",
    skill: "React JS",
    // skill: "Aws & Devops",
  },
  {
    id: 5,
    name: "Bhanu",
    review:
      "Azure Administration course was well-structured, and the practical exercises helped me gain confidence in managing Azure resources. Great learning experience!",
    image: student5,
    company_name: "Coderview",
    skill: "Azure Administration",
    // skill: "Aws & Devops",
  },
  {
    id: 6,
    name: "Bharth Kumar",
    review:
      "Excellent teaching, covered all topics in Devops and AWS, Azure Fees is also affordable compared to other institutes.",
    image: student6,
    company_name: "Quantiphi",
    skill: "AWS & DevOps",
  },
  {
    id: 7,
    name: "Kiran",
    review:
      "The Python course was perfect for beginners like me. The step-by-step approach and real-world examples made complex concepts easy to grasp.",
    image: student7,
    company_name: "Futurism Technologies Pvt Ltd",
    skill: "Python programming",
    // skill: "Aws & Devops",
  },
  {
    id: 8,
    name: "Jeevan",
    review:
      "Hari sir trainings sessions are excellent. The way of teaching and answering to our queries are really excellent and great.",
    image: student8,
    company_name: "Capgemini",
    skill: "AWS & DevOps",
  },
  {
    id: 9,
    name: "Priya",
    review:
      "The AWS course was a great investment in my career. The instructors were knowledgeable, and the course material was well-organized. Highly recommended for cloud enthusiasts!",
    image: student9,
    company_name: "Accenture",
    skill: "AWS & DevOps",
  },
  {
    id: 10,
    name: "Sambasiva",
    review:
      "Terraform was exactly what I needed to understand infrastructure as code. The hands-on labs and clear explanations made the learning process smooth.",
    image: student10,
    company_name: "Quantiphi analiystics and solution",
    skill: "Terraform",
  },
  {
    id: 11,
    name: "Arun",
    review:
      "Enrolling in the React JS course was a decision I won't regret. The interactive sessions and real-world projects made learning React a breeze. I feel confident in my front-end skills now.",
    image: student11,
    company_name: "Teliolabs communications",
    skill: "React JS",
    // skill: "Aws & Devops",
  },
  {
    id: 12,
    name: "Babitha",
    review:
      "I can confidently say that the Java Full Stack Development course is a must for aspiring developers. The comprehensive curriculum and industry-relevant projects set the stage for success.",
    image: student12,
    company_name: "Coderview",
    // skill: "Aws & Devops",
    skill: "Java Full Stack",
  },
];

const HomeReviewCarousel = () => {
  const [index, setIndex] = useState(0);
  const [cardsPerSlide, setCardsPerSlide] = useState(1);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const chunkReviews = (reviews, size) => {
    const chunkedReviews = [];
    for (let i = 0; i < reviews.length; i += size) {
      chunkedReviews.push(reviews.slice(i, i + size));
    }
    return chunkedReviews;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setCardsPerSlide(3);
      } else if (window.innerWidth >= 768) {
        setCardsPerSlide(2);
      } else {
        setCardsPerSlide(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const chunkedReviews = chunkReviews(reviewList, cardsPerSlide);

  const navigateLeft = () => {
    handleSelect((index - 1 + chunkedReviews.length) % chunkedReviews.length);
  };

  const navigateRight = () => {
    handleSelect((index + 1) % chunkedReviews.length);
  };

  return (
    <div className="container review-container">
      <div className="carousel-container">
        <Carousel activeIndex={index} onSelect={handleSelect} interval={7000}>
          {chunkedReviews.map((reviewsGroup, idx) => (
            <Carousel.Item key={idx}>
              <div className="d-flex justify-content-center">
                {reviewsGroup.map((review, reviewIdx) => (
                  <Card key={review.id} className="card custom-card">
                    <img
                      src={OpenDoubleqoute}
                      alt="OpenDoublequote"
                      className="openDoublequote-svg"
                      loading="lazy"
                    />
                    <Card.Img
                      variant="top"
                      src={review.image}
                      alt={review.name}
                    />
                    <Card.Body>
                      <Card.Text className="review-content">
                        {review.review}
                      </Card.Text>
                    </Card.Body>

                    <Card.Footer className="card-footer">
                      <span className="name">{review.name}</span>
                      <span className="company">
                        Company: <b>{review.company_name}</b>
                      </span>
                      <span className="skill">
                        Skills: <b>{`${review.skill}`}</b>
                      </span>
                    </Card.Footer>
                  </Card>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <img
          src={reviewLeftArrow}
          alt="leftArrowreview"
          className="leftslide-carousel-reviews-arrow-image"
          onClick={navigateLeft}
          loading="lazy"
        />
        <img
          src={reviewRightArrow}
          alt="rightArrowreview"
          className="rightslide-carousel-reviews-arrow-image"
          onClick={navigateRight}
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default HomeReviewCarousel;
