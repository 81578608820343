import React, { useEffect } from "react";
import { FaRebel, FaRenren, FaSlack } from "react-icons/fa";
import DataPrivacy from "../../images/privacy_policy.png";
import ReactGA from "react-ga4";
export default function PrivacyPolicy() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <div className="about-us privacy_policy">
      <div className="sub-banner">
        <h1 className="h1-large">Privacy & Data Policy</h1>
      </div>

      <div className="about-javahome content-bg-color policy_content">
        <div className="container">
          <div className="common-block">
            <div className="row protect_data">
              <div className="col-lg-4 col-xl-4">
                <img
                  src={DataPrivacy}
                  alt="privacy"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-8 col-xl-8">
                <h2>Privacy & Data Policy</h2>
                <p className="des-txt">
                  At Java Home Cloud, we value your privacy and take the
                  protection of your personal data seriously. This policy
                  outlines how we collect, use, and protect your personal
                  information.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="common-block common-block-2 privacy_block">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-12 col-xl-12">
                <div className="row protect_data">
                  <div className="col-lg-12">
                    <h2>How we protect our data?</h2>
                    <div className="data_protect">
                      <ul>
                        <li>Collection of Information</li>
                        <p>
                          Get unlimited access to 4,000+ of our top coursesWe
                          collect personal information from you when you visit
                          our website, register for an account, subscribe to our
                          newsletter, or fill out a form. This information may
                          include your name, email address, phone number, and
                          other personal details.
                        </p>
                        <li>Use of Information</li>
                        <p>
                          We use the personal information we collect to provide
                          you with the services you request, to communicate with
                          you, and to improve our website and services. We may
                          also use your information to send you promotional
                          offers, newsletters, or other marketing materials, but
                          only if you have given us your consent
                        </p>
                        <li>Collection of Information</li>
                        <p>
                          Get unlimited access to 4,000+ of our top coursesWe
                          collect personal information from you when you visit
                          our website, register for an account, subscribe to our
                          newsletter, or fill out a form. This information may
                          include your name, email address, phone number, and
                          other personal details.
                        </p>
                        <li>Protection of Information</li>
                        <p>
                          We take the protection of your personal information
                          seriously and have implemented security measures to
                          prevent unauthorized access, disclosure, or misuse. We
                          use SSL encryption to protect your information during
                          transmission and store your data in secure databases.
                        </p>
                        <li>Sharing of Information</li>
                        <p>
                          We may share your personal information with
                          third-party service providers who assist us in
                          providing our services or running our website. We may
                          also disclose your information if required by law or
                          to protect our rights or the rights of others.
                        </p>
                        <li>Cookies</li>
                        <p>
                          We use cookies on our website to improve your browsing
                          experience and analyze website traffic. Cookies are
                          small data files that are placed on your device when
                          you visit our website. You can control the use of
                          cookies through your browser settings.
                        </p>
                        <li>Your Rights</li>
                        <p>
                          You have the right to access, update, or delete your
                          personal information. You can do so by contacting us
                          at [contact email or address]. You also have the right
                          to object to the processing of your personal data or
                          to withdraw your consent at any time.
                        </p>
                        <li>Changes to this Policy</li>
                        <p>
                          We may update this policy from time to time, and any
                          changes will be posted on our website. By continuing
                          to use our website, you consent to any changes made to
                          this policy.
                        </p>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mission-vision-block" style={{ textAlign: "center" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pe-0 ps-5 mobile_vision">
                <h3>Anonymous non-personal Data Policy</h3>
                <p>
                  JHC does not share personal data with any third party except
                  information required to function correctly user might visit
                  our site anonymously, however for billing, security and
                  accounting reason users may be requested to enter their email
                  name, address, company details, and credit card information as
                  needed, This will only be collected from users when
                  registering an account and only if the users enter the data
                  voluntarily, we do not store any personal information about
                  your visit that are not entered voluntarily.
                </p>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="box_container">
                    <div className="box">
                      <div className="box_heading">
                        <span>Non-Personal Data Only</span>
                      </div>
                      <div className="box_body">
                        we use a service called Matomo that strictly only tracks
                        non-personal data that doers not include any user
                        identifiable information
                      </div>
                    </div>
                    <div className="box">
                      <div className="box_heading">
                        <span>100% Anonymous</span>
                      </div>
                      <div className="box_body">
                        we use a service called Matomo that strictly only tracks
                        non-personal data that doers not include any user
                        identifiable information
                      </div>
                    </div>
                    <div className="box">
                      <div className="box_heading">
                        <span>Not Shared</span>
                      </div>
                      <div className="box_body">
                        we use a service called Matomo that strictly only tracks
                        non-personal data that doers not include any user
                        identifiable information
                      </div>
                    </div>
                  </div>
                  {/* <div className="container">
                    <div className="web_data">
                      <div className="row">
                        <div className="col-lg-6 col-xl-6">
                          <p>
                             we use a service called Matomo that strictly only tracks
                        non-personal data that doers not include any user
                        identifiable information
                          </p>
                        </div>
                        <div className="col-lg-6 col-xl-6">
                          <p>
                             we use a service called Matomo that strictly only tracks
                        non-personal data that doers not include any user
                        identifiable information
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
