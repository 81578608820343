export const tabsData = [
  {
    title: "Overview",
  },
  {
    title: "Curriculum",
  },
  {
    title: "FAQs",
  },
  {
    title: "Reviews",
  },
];
