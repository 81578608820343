import React from "react";
import whatsAppIcon from "./assets/whatsapp.webp";
import "./RobotoStyle.css";
// import RobotoChatImage from "./assets/robot-chat.svg";
// import CreateWhatsappChatWidget from "../whatsAppChat/CreateWhatsappChatWidget";

const RobotoChat = () => (
  <a
    href="https://wa.me/9886611117?text=Hello%2C%20I%27m%20looking%20for%20an%20IT%20Course"
    target="_blank"
    title="WhatsApp Chat"
  >
    <div className="roboto">
      <div className="cylinder d-flex justify-content-center align-items-center">
        <img src={whatsAppIcon} alt="WhatsApp Chat icon" />
        <span className="chat-text">Chat with us</span>
      </div>
    </div>
  </a>
);

export default RobotoChat;

{
  /* <div className="roboto">
  <a href="#contacts">
    <div className="roboto-chat">
      <img src={RobotoChatImage} alt="Chat" />
    </div>
  </a>
</div> */
}

{
  /* <CreateWhatsappChatWidget /> */
}
