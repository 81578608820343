import React, { useEffect, useState } from "react";
import "./style.css";
import "./Certificate.css";
import img from "./assets/badge.png";
import logo from "./assets/jhc.png";
import dsg from "./assets/Design.png";
import bottom from "./assets/bottom1.png";
import sign from "./assets/sig.png";
import html2pdf from "html2pdf.js";

const Certificate = ({ certificateId }) => {
  const downloadPDF = () => {
    setIsDownloading(true);
    const element = document.getElementById("certificate-content");

    setTimeout(() => {
      html2pdf(element, {
        margin: 5,
        height: 400,
        filename: "certificate.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      });
    }, 500);
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const [certificateData, setCertificateData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("X-Channel", "app");

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const url = `${process.env.REACT_APP_API_BASE_URL}/v1/verify/${certificateId}`;
        console.log(url);
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Fetched data:", result);
        setCertificateData(result);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [certificateId]);

  return (
    <>
      {certificateData ? (
        <div
          className="bdy"
          id="certificate-content"
          style={{
            backgroundColor: "white",
            paddingBottom: "5px",
            width: "100%",
            height: "100%",
          }}
        >
          <div className="top-dg">
            <div className="top-dg design">
              <img src={dsg} width={600} alt="Design" />
            </div>
            <img src={logo} width={250} className="logo" alt="Logo" />
          </div>

          <div className="top-header">
            <div className="certificate-title">CERTIFICATION OF COMPLETION</div>
            <div className="title-sec">IS PRESENTED TO</div>
          </div>

          <div className="middle-body">
            <div className="std-name ">
              <h1>{certificateData.certificate_data.name}</h1>
            </div>
            <hr style={{ width: 400 }} className="hr-line" />
            <div className="para">
              for completing{" "}
              {certificateData.certificate_data.courseName || "Unknown Course"}{" "}
              Course
            </div>
            {Array.isArray(certificateData.certificate_data.courseName) &&
              certificateData.certificate_data.courseName.map(
                (course, index) => <div key={index}>{course}</div>
              )}
          </div>

          <div style={{ marginBottom: 0 }}>
            <div className="leftside-bottom">
              <div>
                <img src={sign} width={100} alt="Signature" />
              </div>
              <hr style={{ width: 200 }} />
              <div>CEO of Java Home Cloud</div>
            </div>

            <div className="img-bottom">
              <img src={img} style={{ width: 70 }} alt="Badge" />
            </div>

            <div className="rightside-bottom">
              <div style={{ paddingBottom: 1, fontWeight: 600, fontSize: 20 }}>
                Issued On{" "}
              </div>
              <div>DATE: {certificateData.certificate_data.dateIssued}</div>
              <div>
                Certificate Id:{" "}
                {certificateData.certificate_data.certificate_id}
              </div>
            </div>

            <img
              src={bottom}
              width={1240}
              height={150}
              alt="Bottom"
              style={{ marginBottom: 0 }}
            />
          </div>
          {isDownloading ? null : (
            <div>
              <button
                className="button-7"
                role="button"
                onClick={downloadPDF}
                style={{ marginBottom: "5px" }}
              >
                Download PDF
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <p>Loading certificate data...</p>
          {isDownloading ? null : (
            <button
              className="button-7"
              role="button"
              onClick={downloadPDF}
              style={{ marginBottom: "5px" }}
            >
              Download PDF
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default Certificate;
