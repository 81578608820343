import React from "react";
import { Link } from "react-router-dom";
import SocialA1 from "../../images/social-a1.jpg";
import SocialA2 from "../../images/social-a2.jpg";
import SocialA3 from "../../images/social-a3.jpg";
import SocialA4 from "../../images/social-a4.jfif";



const activityList = [
  { id: 1, activity: "What children love about books", image: SocialA1 },
  {
    id: 2,
    activity: "Provide resources and educational materials",
    image: SocialA2,
  },
  {
    id: 3,
    activity: "Our book donation program is empowering children to develop",
    image: SocialA3,
  },
  {
    id: 4,
    activity: "Learning and reach their full potential",
    image: SocialA4,
  },
];

export default function SocialActivity() {
  // const navigate = useNavigate();

  // const handleSelected = (id) => {
  //   navigate(`/socialActivity/${id}/details`);
  // };

  return (
    <div className="social-page">
      <div className="sub-banner">
        <h1 className="h1-large">Get Inspired Today By Social Activities</h1>
      </div>

      <div className="social-activity-section">
        <div className="container">
          <h3>Our Social Activities</h3>
          <p className="social-text">
            Our Social Activities are designed to engage and empower the
            community. From educational workshops to fun-filled events, we offer
            a variety of programs that encourage learning, collaboration, and
            personal growth.At Java Home Cloud, we believe in the power of
            community engagement. Our social activities bring people together
            for a cause, offering learning opportunities, team-building events,
            and hands-on experiences that make a real difference. Join us in
            making a positive impact! Our social activities focus on nurturing
            skills, building strong relationships, and contributing to society
            through educational and fun-filled events.
          </p>
          <div className="row">
            {activityList &&
              activityList.map(({ id, activity, image }, idx) => (
                <div
                  className="col-lg-3 col-md-6 md-md-4 "
                  key={id}
                  style={{ marginBottom: "20px" }}
                >
                  <Link
                    className="social-a"
                    to={`/socialActivityDetails/${id}`}
                  >
                    <div className="post-img">
                      <img
                        src={image}
                        alt=""
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                    <h2 className="social-a-title">{activity}</h2>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
