import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import AxiosClient from "../../js/axiosClient";
import ReactGA from "react-ga4";

import HomeSlider from "../../components/home-slider";
import LearnSection from "../../components/sections";
import FilterTabs from "../../components/filter-tabs";
import PopularTeacher from "../../components/popular-teacher-section";
import "../../css/styles.css";
import SpecializedServices from "../../components/specialized-services";
import CounterCell from "../../components/counter-cell";
import SocialActivities from "../../components/social-activities";
import OurTrainingApproach from "../../components/our-training-approach/OurTrainingApproach";
import UpcomingBatches from "../../components/upcoming-batches";
import HomeReviewCarousel from "../../components/review-carousel/HomeReviewCarousel";

const axiosClient = new AxiosClient({});

export default function Home() {
  const fetchData = async () => {
    try {
      const response = axiosClient.request({
        url: process.env.REACT_APP_API_BASE_URL + "/v1/home",
        method: "GET",
        headers: {
          "X-Channel": "app",
        },
      });
      if (response) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <React.Fragment>
      <HomeSlider />
      {/* <OurTrainingApproach /> */}
      <LearnSection />
      {/* <FilterTabs page="home" /> */}
      <FilterTabs page="courses" />
      <PopularTeacher />
      <UpcomingBatches />
      <SpecializedServices />
      <HomeReviewCarousel />
      <CounterCell />
      <SocialActivities />
      {/* <ContactForm /> */}
      {/* <Footer /> */}

      {/* Home page Upcoming Batches popup button */}
      <a className="btn upcoming-popup-btn" href="#batches">
        Upcoming Batches
      </a>
    </React.Fragment>
  );
}
