import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { tabsData } from "./constants";
import TabsComponent from "../../components/tabsComponent";
import { cloneCopy } from "../../util";
import PlacementImg from "../../images/placement-video.png";
import PlacementImage from "../../images/PlacementImage.jpg";
import Accordion from "react-bootstrap/Accordion";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import ProgressLineBar from "../../components/progress-bar/ProgressLineBar";
import RatingReview from "../../components/rating-review/rating";
import Comments from "../../components/comments";
import JenkinsYoutubePlayer from "./jenkinnYoutubeVideo";
import { useParams } from "react-router-dom";
import DownloadSyllabus from "./DownloadSyllabus";
import AxiosClient from "../../js/axiosClient";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import ContactForm from "../../components/contact-form";
import BookYourSlot from "./bookYourSlot";
import CourseCurriculum from "./CourseCurriculum";
import CourseInfo from "./CourseInfo";
import CourseOverview from "./CourseOverview";

const axiosClient = new AxiosClient({});

export default function CourseDetails() {
  // State variables and functions for managing syllabus modal, video modal, and course details
  const [showSyllabus, setSyllabusShow] = useState(false);
  const [showBookYourSlot, setBookYourSlot] = useState(false);
  const handleSyllabusClose = () => setSyllabusShow(false);
  const handleSyllabusShow = () => setSyllabusShow(true);
  const handleBookYourSlotClose = () => setBookYourSlot(false);
  const handleBookYourSlotShow = () => setBookYourSlot(true);

  // Get ID from URL
  const params = useParams();
  const [subString, setSubString] = useState("");
  const [courseDetail, setCourseDetail] = useState([]);

  const getAllData = async () => {
    try {
      const url =
        process.env.REACT_APP_API_BASE_URL + `/v2/courses/${params.courseId}`;
      const response = await axiosClient.request({
        url: url,
        method: "GET",
        headers: {
          "X-Channel": "app",
        },
      });
      if (response) {
        // Set course details and learning outcomes substring
        // console.log(response.data.seoMetaDescription)
        setCourseDetail(response.data);
        let string = response.data.learningOutcomes;
        if (string[string.length - 1] === ".") {
          string = string.slice(0, -1);
        }
        setSubString(string);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllData();
  }, [params.courseId]);

  const [toggleState, setToggleState] = useState(1);
  const [selectedTab, setSelectedTab] = useState(tabsData[0].content);
  const [courses, setCourses] = useState();
  const toggleTab = useCallback((index) => {
    setToggleState(index);
    setSelectedTab(tabsData[index - 1].content);
  }, []);

  useEffect(() => {
    const cloneData = cloneCopy(tabsData);
    setSelectedTab(cloneData[0].content);
    setCourses(cloneData);
  }, [courseDetail]);

  // Manage video modal
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState();
  const handleClose = () => {
    setShow(false);
    setSrc(null);
  };
  const handleShow = (src) => {
    if (src) {
      setShow(true);
      setSrc(src);
    }
  };

  // Send GA pageview
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="course-detail-view">
      {/* Helmet for setting SEO meta tags */}
      <Helmet>
        <title>
          {courseDetail ? courseDetail.seoTitle : "Java Home Cloud"}
        </title>
        <meta
          name="description"
          content={
            courseDetail
              ? courseDetail.seoMetaDescription
              : "Best Training Institute"
          }
        />
        <meta
          name="keywords"
          content={
            courseDetail ? courseDetail.seoMetaKeywords : "Best DevOps Courses"
          }
        />
      </Helmet>

      {/* Course details sub banner */}
      <div className="course-detail-sub-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6">
              <div className="course-detail-heading">
                <Breadcrumb>
                  <Breadcrumb.Item href="/courses">Courses</Breadcrumb.Item>
                  <Breadcrumb.Item active>Courses View</Breadcrumb.Item>
                </Breadcrumb>
                <h2>{courseDetail.courseName}</h2>
                <p className="des-txt">{courseDetail.summary}</p>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6">
              <div className="placement-banner-img video-btn ">
                <img
                  className="img-fluid"
                  src={courseDetail.photo}
                  alt="alternative"
                  // onClick={() => handleShow(courseDetail.courseIntroVideo)}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CourseInfo
        courseDetail={courseDetail}
        handleSyllabusShow={handleSyllabusShow}
        handleBookYourSlotShow={handleBookYourSlotShow}
      />
      {/* Modal for downloading syllabus */}
      <DownloadSyllabus show={showSyllabus} handleClose={handleSyllabusClose} />
      {/* modal for book your slot  */}
      <BookYourSlot
        showModal={showBookYourSlot}
        handleClose={handleBookYourSlotClose}
        coursename={courseDetail.courseName}
      />

      <div className="course-details-tabs">
        <div className="container">
          <TabsComponent
            courses={courses}
            toggleState={toggleState}
            toggleTab={toggleTab}
          />

          {/* Course details tabs */}
          <div className="content-tabs">
            <div className="content active-content">
              {toggleState === 1 && (
                <CourseOverview courseDetail={courseDetail} />
              )}

              {/* Course curriculum tab */}
              {toggleState === 2 && (
                <CourseCurriculum courseDetail={courseDetail} />
              )}

              {/* FAQ tab */}
              <div className="tab-accordions faq-accordions">
                <Accordion defaultActiveKey="0">
                  {toggleState === 3 &&
                    courseDetail.faqs &&
                    courseDetail.faqs.map((item, index) => (
                      <div className="row m-0" key={index}>
                        <Accordion.Item
                          eventKey={`${index}`}
                          key={index + 1}
                          className="tab-accord"
                        >
                          <Accordion.Header>
                            <h5>
                              {item.questionNumber} {item.question}
                            </h5>
                            <div className="faq-thumb">
                              <button type="button" className="btn-none">
                                <FaRegThumbsUp /> <span>11</span>
                              </button>
                              <button type="button" className="btn-none">
                                <FaRegThumbsDown /> <span>0</span>
                              </button>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>{item.answer}</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </div>
                    ))}
                </Accordion>
              </div>

              {/* Progress and rating tab */}
              {toggleState === 4 && (
                <>
                  <div className="row progress_rating mx-0">
                    <div className="col-lg-3 col-md-4 p-0">
                      <RatingReview />
                    </div>
                    <div className="col-lg-9 col-md-8">
                      <ProgressLineBar />
                    </div>
                  </div>

                  <div className="row comment_container mx-0">
                    <div className="col-lg-12">
                      <Comments />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Modal for playing video */}
      <JenkinsYoutubePlayer show={show} handleClose={handleClose} src={src} />
    </div>
  );
}
